import {
	ACTIVE_ACCORDION_ON_FILTER,
	CLEAR_SEARCHED_CANDIDATES,
	CLEAR_SEARCHED_COMPANIES,
	COMPANIES_SPECIALITIES_SELECTED_CONNECTIVITY_LOGIC,
	GET_SEARCHED_CANDIDATES_DONE,
	GET_SEARCHED_CANDIDATES_FAIL,
	GET_SEARCHED_CANDIDATES_INIT,
	GET_SEARCHED_COMPANIES_DONE,
	GET_SEARCHED_COMPANIES_FAIL,
	GET_SEARCHED_COMPANIES_INIT,
	IS_DATE_DROPDOWN_ACTIVE_ON_DE_FILTER,
	IS_SIZE_DROPDOWN_ACTIVE_ON_DE_FILTER,
	IS_TYPE_DROPDOWN_ACTIVE_ON_DE_FILTER,
	RESET_CANDIDATES_FILTERS,
	RESET_COMPANIES_FILTERS,
	SET_ALL_FILTER_FOR_CANDIDATES,
	SET_CANDIDATE_COMPANY_POSITION,
	SET_CANDIDATE_FILTERS,
	SET_CANDIDATE_TARGETLIST_POSITION,
	SET_CANDIDATE_TARGETLIST_TOGGLE,
	SET_COMPANIES_FILTERS_BY_COMPANIES_LIST,
	SET_COMPANY_FILTERS,
	SET_COMPANY_TYPE_FILTER_CONFIG,
	SET_HEADCOUNT_FILTER_CONFIG,
	SET_INDUSTRIES_FILTER_CONFIG,
	SET_JOB_FUNCTION_FILTER_CONFIG,
	SET_JOB_TITLE_FILTER_MODE,
	SET_KEYWORD_FILTER_CONFIG,
	SET_LOCATIONS_FILTER_CONFIG,
	SET_MULTIPLE_FILTER_FOR_CANDIDATE,
	SET_MULTIPLE_FILTER_FOR_COMPANY,
	SET_REVENUE_FILTER_CONFIG,
	TOGGLE_ENHANCE_CANDIDATES_RESULT,
	TOGGLE_ENHANCE_COMPANIES_RESULT,
} from "../mapXActionTypes";

export function resetCompaniesFilters() {
	return {type: RESET_COMPANIES_FILTERS};
}

export function resetCandidatesFilters() {
	return {type: RESET_CANDIDATES_FILTERS};
}

export function setFilterForCompanies(data) {
	return {type: SET_COMPANY_FILTERS, payload: data};
}

export function setMultipleFilterForCompany(payload) {
	return {type: SET_MULTIPLE_FILTER_FOR_COMPANY, payload};
}

export function setFilterForCandidates(data) {
	return {type: SET_CANDIDATE_FILTERS, payload: data};
}

export function setMultipleFilterForCandidates(payload) {
	return {type: SET_MULTIPLE_FILTER_FOR_CANDIDATE, payload};
}

export function setIndustriesFilterConfig(payload) {
	return {type: SET_INDUSTRIES_FILTER_CONFIG, payload};
}

export function setLocationFilterConfig(payload) {
	return {type: SET_LOCATIONS_FILTER_CONFIG, payload};
}

export function setJobFunctionFilterConfig(payload) {
	return {type: SET_JOB_FUNCTION_FILTER_CONFIG, payload};
}

export function setKeywordFilterConfig(payload) {
	return {type: SET_KEYWORD_FILTER_CONFIG, payload};
}

export function setJobTitleFilterMode(payload = "contains" || "close") {
	return {type: SET_JOB_TITLE_FILTER_MODE, payload};
}

export function setPeopleSearchCompanyHeadcountFilterConfig(payload) {
	return {type: SET_HEADCOUNT_FILTER_CONFIG, payload};
}

export function setPeopleSearchCompanyRevenueFilterConfig(payload) {
	return {type: SET_REVENUE_FILTER_CONFIG, payload};
}

export function setPeopleSearchCompanyTypeFilterConfig(payload) {
	return {type: SET_COMPANY_TYPE_FILTER_CONFIG, payload};
}

export function setFilterForCompaniesByCompaniesList(payload) {
	return {type: SET_COMPANIES_FILTERS_BY_COMPANIES_LIST, payload};
}

export function setFilterForCandidatesFilterSearch(payload) {
	return {type: SET_ALL_FILTER_FOR_CANDIDATES, payload};
}

export function setCompanySpecialitiesSelectedConnectivityLogic(payload) {
	return {type: COMPANIES_SPECIALITIES_SELECTED_CONNECTIVITY_LOGIC, payload};
}

// DEPRECATED

export function getSearchedCandidatesInit() {
	return {type: GET_SEARCHED_CANDIDATES_INIT};
}

export function getSearchedCandidatesDone(data) {
	return {type: GET_SEARCHED_CANDIDATES_DONE, payload: data};
}

export function clearSearchedCandidates() {
	return {type: CLEAR_SEARCHED_CANDIDATES};
}

export function getSearchedCandidatesFail(error) {
	return {type: GET_SEARCHED_CANDIDATES_FAIL, payload: error};
}

export function getSearchedCompaniesInit() {
	return {type: GET_SEARCHED_COMPANIES_INIT};
}

export function getSearchedCompaniesDone(data) {
	return {type: GET_SEARCHED_COMPANIES_DONE, payload: data};
}

export function getSearchedCompaniesFail(error) {
	return {type: GET_SEARCHED_COMPANIES_FAIL, payload: error};
}

export function clearSearchedCompanies() {
	return {type: CLEAR_SEARCHED_COMPANIES};
}

export function setActiveAccordionOnFilter(payload) {
	return {type: ACTIVE_ACCORDION_ON_FILTER, payload};
}

export function setIsTypeDropDownActiveOnDEFilter(payload) {
	return {type: IS_TYPE_DROPDOWN_ACTIVE_ON_DE_FILTER, payload};
}

export function setIsSizeDropDownActiveOnDEFilter(payload) {
	return {type: IS_SIZE_DROPDOWN_ACTIVE_ON_DE_FILTER, payload};
}

export function setIsDateDropDownActiveOnDEFilter(payload) {
	return {type: IS_DATE_DROPDOWN_ACTIVE_ON_DE_FILTER, payload};
}

export function setEnhanceCompaniesResult(payload) {
	return {type: TOGGLE_ENHANCE_COMPANIES_RESULT, payload};
}

export function setEnhanceCandidatesResult(payload) {
	return {type: TOGGLE_ENHANCE_CANDIDATES_RESULT, payload};
}

export function setCandidateCompanyPosition(payload) {
	return {type: SET_CANDIDATE_COMPANY_POSITION, payload};
}

export function setCandidateTargetListPosition(payload) {
	return {type: SET_CANDIDATE_TARGETLIST_POSITION, payload};
}

export function setCandidateTargetListToggle(payload) {
	return {type: SET_CANDIDATE_TARGETLIST_TOGGLE, payload};
}
