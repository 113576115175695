import React, {useCallback, useMemo, useState} from "react";
import {
	activeImportPercentage,
	candidatesListSelector,
	searchCandidatesForProjectSelector,
	showLastBulkImportReportSelector,
} from "store/mapx/linkedin-bulk-import/linkedinBulkImportSelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	activeAddPeopleModalForProject,
	clearImportLinkedInProfileInfoForImportByID,
	deleteImportLinkedInURLsForCurrentImport,
	setActiveImportPercentage,
	setBulkImportIdForCompany,
	setImportLinkedInProfileInfoForCurrentImport,
	setSearchProgressCandidateByLinkedInForCurrentImport,
	setShouldOpenBulkImportReport,
	setShowLastImportReport,
	setShowLastImportReportForCompany,
	updateImportLinkedInURLsForCurrentImport,
} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";
import {searchCandidateByLinkedInUrlForProject} from "store/mapx/linkedin-bulk-import/linkedInBulkImportAsyncActions";
import {toast} from "react-toastify";
import {ToastContent} from "components";
import {TUrlList} from "mapx-components/AddPeopleByLinkedIn/types";
import {TState} from "mapx-components/Modals/SearchByLinkedInModal/types";
import {AddPeopleByLinkedIn} from "mapx-components";
import styles from "mapx-components/AddPeopleByLinkedIn/styles.module.scss";
import {
	isLastBulkImportReportDataLoadingSelector,
	lastCompletedCandidateImportByLinkedInForProjectSelector,
} from "store/mapx/background-tasks/backgroundTaskSelectors";
import {sortUrls} from "mapx-components/AddPeopleByLinkedIn/util";

const AddPeople = () => {
	const project = useAppSelector(projectSelector);

	const importPercentage = useAppSelector(activeImportPercentage);

	const urlList = useAppSelector(candidatesListSelector);

	const dispatch = useAppDispatch();

	const importsInProgress = useAppSelector(searchCandidatesForProjectSelector);

	const isLastBulkImportDataLoading = useAppSelector(isLastBulkImportReportDataLoadingSelector);

	const inProgress =
		importsInProgress.id === project?.id && importsInProgress.data && importPercentage < 100;

	// Get the urls intended to be saved by the user for the current project
	const getCurrentProjectURLs = useCallback(
		(linkedinURLs: TUrlList[]) => {
			const urls: TUrlList[] = [];

			if (linkedinURLs && Object.values(linkedinURLs).length > 0) {
				Object.values(linkedinURLs).forEach((value) => {
					if (Number(value?.id) === project?.id) {
						urls.push(value);
					}
				});

				return urls;
			} else {
				return null;
			}
		},
		[project?.id],
	);

	const linkedInUrl = useMemo(() => {
		return getCurrentProjectURLs(urlList);
	}, [urlList, getCurrentProjectURLs]);

	const handleOnSubmit = async (urls: string[]): Promise<TState> => {
		dispatch(setActiveImportPercentage(0));
		toast.success(ToastContent, {
			toastId: "import_started_toaster",
			autoClose: false,
			closeOnClick: false,
			data: {
				title: "The candidate import process has started",
				description: (
					<div>
						<p>You will receive a notification when it is done</p>
					</div>
				),
			},
			delay: 100,
		});

		const response = await dispatch(searchCandidateByLinkedInUrlForProject(urls, project));

		if (response.candidate !== null && response.message === "success") {
			return "success";
		} else if (response.message === "importing") {
			const importInfo = response.importInfo;

			importInfo.linkedin_candidate_urls.map((url: {url: string; status: string}) => {
				const data = {
					id: project?.id,
					importInfo: importInfo,
					status: url.status,
					projectInfo: project,
					targetListId: project.target_list_id,
					linkedInUrl: url.url,
				};

				dispatch(setImportLinkedInProfileInfoForCurrentImport({id: importInfo.id, data}));
				// update the importing states live
				dispatch(
					updateImportLinkedInURLsForCurrentImport([
						{
							projectID: project?.id,
							url: url.url,
							status: url.status,
							reason: "",
						},
					]),
				);
			});

			return "link-submit";
		} else {
			return "link-submit";
		}
	};

	const onSuccessClose = async (projectID: number) => {
		// clear the workflow after everything is done
		dispatch(setShowLastImportReport(false));

		dispatch(setShowLastImportReportForCompany(false));

		dispatch(setShouldOpenBulkImportReport(false));

		dispatch(clearImportLinkedInProfileInfoForImportByID(projectID));

		dispatch(setBulkImportIdForCompany(null));

		dispatch(deleteImportLinkedInURLsForCurrentImport({importID: projectID}));

		dispatch(
			setSearchProgressCandidateByLinkedInForCurrentImport({
				id: projectID,
				data: false,
				targetListId: importsInProgress.targetListId,
				project: importsInProgress.project,
			}),
		);

		toast.dismiss("import_success_toaster");
		toast.dismiss("import_error_toaster");
	};

	const isAllImportCompleted = useCallback(
		(linkedinURLs: TUrlList[]) => {
			// states that contain info for finished imports
			const allowedStatusValues = [
				"success",
				"already exists",
				"error",
				"import error",
				"scraping error",
				"bad url",
				"not found",
				"imported",
				"completed",
			];

			const urls = getCurrentProjectURLs(linkedinURLs);
			let allImportCompleted = false;
			if (urls) {
				allImportCompleted = Object.values(urls)?.every((item) => {
					return allowedStatusValues.includes(item.status?.toLowerCase());
				});
			}

			return allImportCompleted;
		},
		[getCurrentProjectURLs],
	);

	const setInitialState = useMemo((): TState => {
		if (linkedInUrl) {
			const allImportCompleted = isAllImportCompleted(urlList);

			if (allImportCompleted) {
				return "success";
			} else {
				return "link-submit";
			}
		}

		return "link-submit";
	}, [urlList, linkedInUrl, isAllImportCompleted]);

	const toggleModalDisplay = useCallback(
		(displayed: boolean) => {
			if (displayed) {
				dispatch(activeAddPeopleModalForProject(project?.id));
			} else {
				dispatch(activeAddPeopleModalForProject(null));
			}
		},
		[dispatch, project?.id],
	);

	const [modalDisplay, setModalDisplay] = useState<boolean>(false);

	const importOngoing = useMemo(() => {
		return (
			Object.entries(importsInProgress).length > 0 &&
			importsInProgress.data &&
			importsInProgress.id === project?.id
		);
	}, [importsInProgress, project?.id]);

	const showLastBulkImportReport = useAppSelector(showLastBulkImportReportSelector);

	const lastCompletedCandidateImportByLinkedInForProject = useAppSelector(
		lastCompletedCandidateImportByLinkedInForProjectSelector,
	);

	const importingForOtherProject = useMemo(() => {
		return (
			Object.entries(importsInProgress).length > 0 &&
			importsInProgress.data &&
			importsInProgress.id !== project?.id
		);
	}, [importsInProgress, project?.id]);

	const shouldRenderLastLinkedInBulkImportReport = useMemo(() => {
		const lastCompletedImport = lastCompletedCandidateImportByLinkedInForProject[project?.id];

		return (
			lastCompletedImport?.id &&
			showLastBulkImportReport &&
			!importingForOtherProject &&
			!importOngoing
		);
	}, [
		showLastBulkImportReport,
		lastCompletedCandidateImportByLinkedInForProject,
		importOngoing,
		project?.id,
		importingForOtherProject,
	]);

	const importHistoryData = useMemo(() => {
		return sortUrls(
			lastCompletedCandidateImportByLinkedInForProject[project?.id]?.importInfo
				.linkedin_candidate_urls,
		);
	}, [project?.id, lastCompletedCandidateImportByLinkedInForProject]);

	return (
		<AddPeopleByLinkedIn
			containerClass={styles.addPeopleByLinkedIn}
			onSubmit={handleOnSubmit}
			source={"project"}
			inProgress={inProgress}
			importOngoing={importOngoing}
			importsInProgress={importsInProgress}
			onSuccessClose={onSuccessClose}
			linkedInUrl={linkedInUrl}
			isLastBulkImportDataLoading={isLastBulkImportDataLoading}
			initialState={setInitialState}
			toggleModalDisplay={toggleModalDisplay}
			modalDisplay={modalDisplay}
			setModalDisplay={setModalDisplay}
			renderLastCompletedReport={shouldRenderLastLinkedInBulkImportReport}
			lastCompletedReportData={importHistoryData}
			importPercentage={importPercentage}
			lastCompletedImportID={
				lastCompletedCandidateImportByLinkedInForProject[project?.id]?.id
			}
		/>
	);
};

export default AddPeople;
