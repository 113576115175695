import {
	BACKGROUND_BULK_IMPORTS_IN_PROGRESS,
	CLEAR_PROJECT_CANDIDATE_ASSESSMENT_PROCESS,
	SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT,
	SET_PROJECT_CANDIDATE_ASSESSMENT_PROCESS,
} from "../mapXActionTypes";
import {AnyAction} from "redux";
import {TBackgroundTaskReducerInitialState} from "store/mapx/background-tasks/types";

export const backgroundTaskReducerInitialState: TBackgroundTaskReducerInitialState = {
	isLastBulkImportReportDataLoading: true,
	lastCompletedCandidateImportByLinkedInForProject: {}, // used to store completed bulk import urls
	activeCandidateAssessmentForProject: null,
};

// @TODO SAVE BACKGROUND TASK BY DIFFERENT STANDALONE FEATURES,
// @TODO SO THAT WE CAN CREATE CUSTOM SELECTORS TO ACCESS THE NEEDED INFORMATION

const backgroundTaskReducer = (state = backgroundTaskReducerInitialState, action: AnyAction) => {
	switch (action.type) {
		case BACKGROUND_BULK_IMPORTS_IN_PROGRESS:
			return {
				...state,
				isLastBulkImportReportDataLoading: action.payload,
			};

		case SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT:
			return {
				...state,
				lastCompletedCandidateImportByLinkedInForProject: {
					[action.payload.id]: action.payload.data, // sets object for project by id
				},
			};
		case SET_PROJECT_CANDIDATE_ASSESSMENT_PROCESS:
			return {
				...state,
				activeCandidateAssessmentForProject: action.payload,
			};
		case CLEAR_PROJECT_CANDIDATE_ASSESSMENT_PROCESS:
			return {
				...state,
				activeCandidateAssessmentForProject: null,
			};
		default:
			return state;
	}
};

export default backgroundTaskReducer;
