import {
	ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT,
	ACTIVE_IMPORT_PERCENTAGE,
	CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_IMPORT_BY_ID,
	DELETE_LINKEDIN_PROFILES_IN_PROGRESS,
	GET_CANDIDATES_BY_FILTER_DONE,
	IMPORT_LINKEDIN_PROFILE_IN_PROGRESS,
	IMPORT_LINKEDIN_PROFILES_IN_PROGRESS,
	RESET_FILTERED_CANDIDATES,
	SEARCH_LINKEDIN_PROFILE_IN_PROGRESS_FOR_CURRENT_IMPORT,
	SET_BACKGROUND_BULK_IMPORTS_IN_PROGRESS_TO_WATCHER,
	SET_BULK_IMPORT_ID_FOR_COMPANY,
	SET_CURRENT_IMPORT_FINISHED,
	SET_IMPORT_LINKEDIN_PROFILE_INFO,
	SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_COMPANY,
	SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT,
	SET_SELECTED_CANDIDATES_DONE,
	SET_SHOW_LAST_IMPORT_REPORT,
	SET_SHOW_IMPORT_REPORT,
	SET_SHOW_LAST_IMPORT_REPORT_FOR_COMPANY,
	UPDATE_LINKEDIN_PROFILES_IN_PROGRESS,
} from "../mapXActionTypes";
import {
	TSetImportLinkedinProfileInfoForCompanyPayload,
	TSetImportLinkedinProfileInfoForCurrentImportPayload,
} from "mapx-components/AddPeopleByLinkedIn/types";
import {STCompany} from "api/companyApi/types";

export function setSelectedCandidatesDone(data: boolean) {
	return {type: SET_SELECTED_CANDIDATES_DONE, payload: data};
}

export function resetFilteredCandidates() {
	return {type: RESET_FILTERED_CANDIDATES};
}

export function setBulkImportStateFromBackgroundInProgressData(payload: object) {
	return {type: SET_BACKGROUND_BULK_IMPORTS_IN_PROGRESS_TO_WATCHER, payload};
}

export function importLinkedInCandidateInProgress(payload: boolean) {
	return {type: IMPORT_LINKEDIN_PROFILE_IN_PROGRESS, payload};
}

export function setImportLinkedInProfileInfo(payload: object) {
	return {type: SET_IMPORT_LINKEDIN_PROFILE_INFO, payload};
}

/**
 ** @param payload: {projectId: true|false}
 */

export function setSearchProgressCandidateByLinkedInForCurrentImport(payload: object) {
	return {type: SEARCH_LINKEDIN_PROFILE_IN_PROGRESS_FOR_CURRENT_IMPORT, payload};
}

export function setBulkImportIdForCompany(payload: Nullable<STCompany>) {
	return {type: SET_BULK_IMPORT_ID_FOR_COMPANY, payload};
}

/**
 ** @param payload: {id: projectId, data: object } object contains import information, status receives from BE
 */

export function setImportLinkedInProfileInfoForCurrentImport(
	payload: TSetImportLinkedinProfileInfoForCurrentImportPayload,
) {
	return {type: SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT, payload};
}

export function setImportLinkedInProfileInfoForCompany(
	payload: TSetImportLinkedinProfileInfoForCompanyPayload,
) {
	return {type: SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_COMPANY, payload};
}

export function getCandidatesByFilterDone(data: void) {
	return {type: GET_CANDIDATES_BY_FILTER_DONE, payload: data};
}

export function clearImportLinkedInProfileInfoForImportByID(projectId: number | string) {
	return {type: CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_IMPORT_BY_ID, payload: projectId};
}

export function activeAddPeopleModalForProject(payload: number | null) {
	return {type: ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT, payload};
}

export function setActiveImportPercentage(payload: number) {
	return {type: ACTIVE_IMPORT_PERCENTAGE, payload};
}

export function setBulkImportCurrentImportFinished(payload: {
	importing: boolean;
	projectID: number;
}) {
	return {type: SET_CURRENT_IMPORT_FINISHED, payload};
}

export function setImportLinkedInURLsListForCurrentImport(payload: object) {
	return {type: IMPORT_LINKEDIN_PROFILES_IN_PROGRESS, payload};
}

export function updateImportLinkedInURLsForCurrentImport(payload: object) {
	return {type: UPDATE_LINKEDIN_PROFILES_IN_PROGRESS, payload};
}

export function deleteImportLinkedInURLsForCurrentImport(payload: {importID: number}) {
	return {type: DELETE_LINKEDIN_PROFILES_IN_PROGRESS, payload};
}

export function setShouldOpenBulkImportReport(payload: boolean) {
	return {type: SET_SHOW_IMPORT_REPORT, payload};
}

export function setShowLastImportReport(payload: boolean) {
	return {type: SET_SHOW_LAST_IMPORT_REPORT, payload};
}

export function setShowLastImportReportForCompany(payload: boolean) {
	return {type: SET_SHOW_LAST_IMPORT_REPORT_FOR_COMPANY, payload};
}
