import {TRootState} from "types";
import {createSelector} from "reselect";
import {activeLastCandidateAssessmentForProjectSelector} from "store/mapx/background-tasks/backgroundTaskSelectors";
import {STBackgroundTaskResponse} from "api/backgroundTaskApi/types";

export const briefCriteriaProjectAssessmentSelector = (state: TRootState) =>
	state.mapxProject.brief_criteria_project_assessment;

export const projectIdSelector = (_: TRootState, id: number) => id;
export const candidateIdSelector = (_: TRootState, __: TRootState, candidateId: number) =>
	candidateId;

export const selectedCandidateIdsForAssessmentSelector = (state: TRootState) =>
	state.mapxProject.selectedCandidateIdsForAssessment || [];

export const isCandidateSelectedForAssessmentSelector = createSelector(
	selectedCandidateIdsForAssessmentSelector,
	candidateIdSelector,
	(selectedCandidateIdsForAssessment: number[] = [], candidateId) =>
		selectedCandidateIdsForAssessment.includes(candidateId),
);

export const isCurrentProjectCandidateScoringInProgressSelector = createSelector(
	activeLastCandidateAssessmentForProjectSelector,
	projectIdSelector,
	(candidateAssessment: STBackgroundTaskResponse, projectId) => {
		return (
			candidateAssessment &&
			candidateAssessment?.project_id === projectId &&
			candidateAssessment?.type === "Candidate Assessment" &&
			(candidateAssessment?.status === "Created" ||
				candidateAssessment?.status === "In Progress")
		);
	},
);

export const isAnyProjectCandidateScoringInProgressSelector = createSelector(
	activeLastCandidateAssessmentForProjectSelector,
	(candidateAssessment: STBackgroundTaskResponse) => {
		return (
			candidateAssessment &&
			candidateAssessment?.type === "Candidate Assessment" &&
			(candidateAssessment?.status === "Created" ||
				candidateAssessment?.status === "In Progress")
		);
	},
);

export const isCurrentCandidateForCurrentProjectScoringInProgressSelector = createSelector(
	activeLastCandidateAssessmentForProjectSelector,
	projectIdSelector,
	candidateIdSelector,
	(candidateAssessment: STBackgroundTaskResponse, projectId, candidateId) => {
		if (
			candidateAssessment &&
			candidateAssessment?.project_id === projectId &&
			candidateAssessment?.type === "Candidate Assessment" &&
			(candidateAssessment?.status === "Created" ||
				candidateAssessment?.status === "In Progress")
		) {
			const cc = candidateAssessment.candidate_assessments?.find(
				(item) => item.candidate_id === candidateId && !item.is_assessed,
			);

			if (cc) {
				return true;
			}
		}

		return false;
	},
);
