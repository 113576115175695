import type {FC} from "react";
import {useEffect, useMemo, useState} from "react";
import classNames from "classnames";
import colors from "styles/themes.module.scss";
import {useAppDispatch, useAppSelector, useModalHook} from "hooks";
import type {TSearchByLinkedInProps, TState, TUrlList} from "./types";
import styles from "./searchByLinkedIn.module.scss";
import Form from "./Form";
import LinkedInBulkImportReport from "mapx-components/Modals/SearchByLinkedInModal/Report";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import Papa from "papaparse";
import {sortUrls} from "../../AddPeopleByLinkedIn/util";
import {showBulkImportReportSelector} from "store/mapx/linkedin-bulk-import/linkedinBulkImportSelectors";
import {setShouldOpenBulkImportReport} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";

const SearchByLinkedInModal: FC<TSearchByLinkedInProps> = ({
	setModalDisplay,
	modalDisplay = true,
	onSubmit,
	inProgress,
	onSuccessClose,
	linkedInUrl,
	linkedInModalTestId = "",
	initialState = "link-submit",
	source,
	importOngoing,
	renderLastCompletedReport,
	lastCompletedReportData,
	isLastBulkImportDataLoading,
	importsInProgress,
	importPercentage,
	lastCompletedImportID,
}) => {
	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{content: {width: "550px", borderRadius: "8px"}},
		modalDisplay,
		colors.mainThemeColor,
	);

	const [state, setState] = useState<TState>("link-submit");

	const dispatch = useAppDispatch();

	const project = useAppSelector(projectSelector);

	const shouldOpenReportModal = useAppSelector(showBulkImportReportSelector);

	const countUrls = (urls: TUrlList[]): number => {
		return urls?.length;
	};

	const closeModal = () => {
		setModalDisplay(false);
	};

	useEffect(() => {
		setState(initialState);
	}, [initialState]);

	const handleSubmit = async (url: string[]) => {
		const response: TState = await onSubmit(url);
		setState(response);
	};

	const handleDoneClick = (projectID: number) => {
		onSuccessClose(projectID);
		setState("link-submit");
	};

	useEffect(() => {
		if (shouldOpenReportModal && state === "success") {
			setModalDisplay(true);
			dispatch(setShouldOpenBulkImportReport(false));
		}
	}, [shouldOpenReportModal, setModalDisplay, state, dispatch]);

	const importingForOtherProject = useMemo(() => {
		return (
			Object.entries(importsInProgress).length > 0 &&
			importsInProgress.data &&
			importsInProgress.id !== project?.id
		);
	}, [importsInProgress, project?.id]);

	const totalCompletedUrls = useMemo(() => {
		return countUrls(lastCompletedReportData);
	}, [lastCompletedReportData]);

	// Helper function to determine the status message
	const getStatusMessage = (status: string): string => {
		const lowerStatus = status?.toLowerCase();
		if (!lowerStatus) return "";

		if (["success", "imported", "completed", "already exists"].includes(lowerStatus)) {
			return "Success";
		} else if (lowerStatus === "not found") {
			return "Bad Url";
		} else if (["import error", "scraping error"].includes(lowerStatus)) {
			return "Sent for manual import";
		}

		return status;
	};

	// Helper function to determine the reason message
	const getReasonMessage = (status: string): string => {
		const lowerStatus = getStatusMessage(status).toLowerCase();
		if (lowerStatus === "bad url") {
			return "Not a valid LinkedIn candidate URL";
		} else if (lowerStatus === "not found") {
			return "This profile was not found";
		} else if (lowerStatus === "sent for manual import") {
			return "This profile has been sent for manual import. If it is a valid URL, it will be visible on MapX within 12 hours";
		}

		return "";
	};

	const downloadReport = (linkedInUrls: TUrlList[] | undefined) => {
		if (!linkedInUrls) return;

		// Helper function to generate a sanitized filename
		const generateFileName = (projectName: string) => {
			const date = new Date().toLocaleDateString();

			if (projectName) {
				return (
					projectName?.replace(/[.]/g, "_").replace(/[\s,]/g, "_") +
					"_" +
					date.replace(/[\s,]/g, "_")
				);
			} else {
				return "Candidate_import" + "_" + date.replace(/[\s,]/g, "_");
			}
		};

		// Process the data for CSV
		const csvData = linkedInUrls.map((item) => ({
			linkedin_url: item.url,
			status: getStatusMessage(item.status),
			reason: getReasonMessage(item.status),
		}));

		// Convert to CSV and trigger download
		const csv = Papa.unparse(csvData);
		const blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.setAttribute("href", url);
		a.setAttribute("download", generateFileName(project?.name));
		a.click();
	};

	return (
		<Modal
			data-testid={linkedInModalTestId}
			style={customStyles}
			isOpen={modalIsOpen}
			contentLabel="Modal"
			onRequestClose={closeModal}
		>
			<div
				data-testid="searchByLinkedinComponent"
				className={classNames("modal", styles.wrapper)}
			>
				{renderLastCompletedReport ? (
					<LinkedInBulkImportReport
						totalURLs={totalCompletedUrls}
						linkedInUrl={lastCompletedReportData}
						handleDownload={(urls) => downloadReport(urls)}
						inProgress={true}
						lastCompletedDisplay={true}
						isLastBulkImportDataLoading={isLastBulkImportDataLoading}
						otherProjectInProgress={importingForOtherProject}
						importsInProgress={importsInProgress}
						importPercentage={importPercentage}
						handleClick={() => handleDoneClick(lastCompletedImportID)}
						importedLinkedInUrls={lastCompletedReportData}
						source={source}
					/>
				) : !importsInProgress.data ? (
					<Form
						handleSubmit={handleSubmit}
						handleDownload={(urls) => downloadReport(urls)}
						inProgress={importsInProgress.data || inProgress}
						linkedInUrl={linkedInUrl}
						source={source}
						importedLinkedInUrls={lastCompletedReportData}
					/>
				) : (
					linkedInUrl && (
						<LinkedInBulkImportReport
							totalURLs={countUrls(linkedInUrl)}
							linkedInUrl={sortUrls(linkedInUrl)}
							handleDownload={(urls) => downloadReport(urls)}
							lastCompletedDisplay={false}
							isLastBulkImportDataLoading={isLastBulkImportDataLoading}
							inProgress={importOngoing && countUrls(linkedInUrl) > 0}
							otherProjectInProgress={importingForOtherProject}
							importsInProgress={importsInProgress}
							importPercentage={importPercentage}
							handleClick={() => handleDoneClick(importsInProgress.id)}
							importedLinkedInUrls={lastCompletedReportData}
							source={source}
						/>
					)
				)}
			</div>
		</Modal>
	);
};

export default SearchByLinkedInModal;
