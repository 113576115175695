import useSearchDebounce from "hooks/useSearchDebounce";
import {AutoComplete} from "mapx-components";
import React, {useCallback, useEffect, useState} from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {useDispatch, useSelector} from "react-redux";
import {clearSearchedCompanies} from "store/mapx/filter/filterActions";
import {getSearchedCompaniesData} from "store/mapx/filter/filterAsyncActions";
import {CompanyItem, ResetFilters, Tag} from "components";
import css from "./styles.module.scss";

function CompanySearchFilter({
	handleOnClick,
	handleRemoveClick,
	selectedCompanies = [],
	onResetClick = undefined,
}) {
	const dispatch = useDispatch();

	const searchedCompanies = useSelector((state) => state.filter.searchedCompanies);

	const [searchTerm, setSearchTerm] = useSearchDebounce(800);

	const [loading, setLoading] = useState(false);

	const searchCompanies = useCallback(
		async (companyName, page) => {
			if (companyName && companyName.length >= 2) {
				setLoading(true);
				await dispatch(getSearchedCompaniesData(companyName, page)).then((response) => {
					if (response?.error && response?.error?.name === "CanceledError") {
						setLoading(true);
					} else {
						setLoading(false);
					}
				});
			}
		},
		[dispatch],
	);

	const [loadingRef] = useInfiniteScroll({
		loading,
		hasNextPage: searchedCompanies?.pagination?.pages > searchedCompanies?.pagination?.page,
		onLoadMore: () => searchCompanies(searchTerm, searchedCompanies?.pagination?.page + 1 || 1),
		rootMargin: "0px 0px 400px 0px",
	});

	useEffect(() => {
		dispatch(clearSearchedCompanies());

		if (searchTerm) {
			searchCompanies(searchTerm, 1);
		}
	}, [searchTerm, dispatch, searchCompanies]);

	return (
		<div>
			<AutoComplete
				handleFilterChange={setSearchTerm}
				handleItemClick={handleOnClick}
				hasMore={loading}
				isLoading={loading}
				notFoundResult="No company found"
				ref={loadingRef}
				suggestions={searchedCompanies.results}
				SuggestionListItemComponent={CompanyItem}
			/>

			{onResetClick && selectedCompanies.length > 0 && (
				<ResetFilters onClick={onResetClick} />
			)}

			<div className={css.tagsWrapper}>
				{selectedCompanies.map((company) => (
					<Tag
						key={company.id}
						onClick={() => {
							handleRemoveClick(company.id);
							setSearchTerm("");
						}}
					>
						{company.name}
					</Tag>
				))}
			</div>
		</div>
	);
}

export default CompanySearchFilter;
