import React, {useCallback, useMemo, useState} from "react";
import styles from "mapx-pages/Project/SavedPeople/styles.module.scss";
import {ActionAltButton} from "mapx-components";
import classNames from "classnames";
import {MagicWandIcon} from "assets/icons";
import FilterIcon from "assets/icons/IconFilter";
import {useAppDispatch, useAppSelector} from "hooks";
import {targetListIDSelector} from "store/mapx/target-list/targetListSelectors";
import {getAllGenerateSummaryCandidateIdsForCurrentProjectSelector} from "store/mapx/project/generateCandidateSummarySelectors";
import customConfirmAlert from "helpers/customConfirmAlert";
import {generateCandidatesSummary} from "store/mapx/project/generateCandidateSummaryAsyncActions";
import {SICandidate} from "api/candidateApi/types";
import {TSavedPeopleHeaderSectionProps} from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/types";
import {BiPhone} from "react-icons/bi";
import {getAllGenerateContactDetailsCandidateIdsForCurrentProjectSelector} from "store/mapx/project/generateCandidateContactDetailsSelectors";
import {generateCandidatesContactDetails} from "store/mapx/project/generateCandidateContactDetailsAsyncActions";
import ExpandAllIcon from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/Icons/expandAllIcon";
import AssessmentIcon from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/Icons/assessmentIcon";
import SummaryViewIcon from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/Icons/summaryViewIcon";
import {activeProjectReportViewOnCandidateCardSelector} from "store/mapx/project/projectSelectors";
import {setActiveProjectReportViewOnCandidateCard} from "store/mapx/project/projectActions";
import {Checkbox} from "components";
import {handleAllCandidateSelectionForAssessment} from "store/mapx/project/projectAssessmentAsyncActions";

const SavedPeopleHeaderSection = ({
	displayAllExperience,
	setDisplayAllExperience,
	currentDisplayedCount,
	totalSavedPeopleCount,
	displayedCandidates,
	hasActiveFilters,
	candidateSelectionTypeForAssessment = "none",
}: TSavedPeopleHeaderSectionProps) => {
	const dispatch = useAppDispatch();

	const activeProjectReportViewOnCandidateCard = useAppSelector(
		activeProjectReportViewOnCandidateCardSelector,
	);

	const targetListID = useAppSelector(targetListIDSelector);

	const [creatingSummaryRequest, setCreatingSummaryRequest] = useState(false);

	const [creatingContactDetailsRequest, setCreatingContactDetailsRequest] = useState(false);

	const isSummaryForInProgressForCurrentProject = useAppSelector<number[]>((state) =>
		getAllGenerateSummaryCandidateIdsForCurrentProjectSelector(state, targetListID),
	);

	const isContactDetailsForInProgressForCurrentProject = useAppSelector<number[]>((state) =>
		getAllGenerateContactDetailsCandidateIdsForCurrentProjectSelector(state, targetListID),
	);

	const generatingSummaryForAll = useMemo(
		() => creatingSummaryRequest || isSummaryForInProgressForCurrentProject?.length > 0,
		[creatingSummaryRequest, isSummaryForInProgressForCurrentProject?.length],
	);

	const generatingContactDetailsForAll = useMemo(
		() =>
			creatingContactDetailsRequest ||
			isContactDetailsForInProgressForCurrentProject?.length > 0,
		[creatingContactDetailsRequest, isContactDetailsForInProgressForCurrentProject?.length],
	);

	const shouldDisplayGenerateAllSummary = useMemo(
		() =>
			totalSavedPeopleCount <= 20 &&
			displayedCandidates.some((candidate) => candidate.summary === null),
		[displayedCandidates, totalSavedPeopleCount],
	);

	const shouldDisplayGenerateAllContactDetails = useMemo(
		() =>
			totalSavedPeopleCount <= 20 &&
			displayedCandidates.some((candidate) => candidate.contact_details === null),
		[displayedCandidates, totalSavedPeopleCount],
	);

	const handleGenerateAllSummariesButtonClick = useCallback(async () => {
		customConfirmAlert({
			yesButtonLabel: "Generate",
			noButtonLabel: "Back",
			title: `This action will generate ${
				displayedCandidates.filter((candidate) => !candidate.summary).length
			} candidate summaries.`,
			message: `Would you like to continue?`,
			alertType: "warning",
			handlePressYes: async () => {
				setCreatingSummaryRequest(true);

				const ids = displayedCandidates
					.filter((item: SICandidate) => item.summary === null)
					.map((item: SICandidate) => item.id);

				await dispatch(generateCandidatesSummary(ids));

				setCreatingSummaryRequest(false);
			},
		});
	}, [dispatch, displayedCandidates]);

	const handleGenerateAllContactDetailsButtonClick = useCallback(async () => {
		customConfirmAlert({
			yesButtonLabel: "Generate",
			noButtonLabel: "Back",
			title: `This action will generate ${
				displayedCandidates.filter((candidate) => !candidate.contact_details).length
			} candidate contact details.`,
			message: `Would you like to continue?`,
			alertType: "warning",
			handlePressYes: async () => {
				setCreatingContactDetailsRequest(true);

				const ids = displayedCandidates
					.filter((item: SICandidate) => item.contact_details === null)
					.map((item: SICandidate) => item.id);

				await dispatch(generateCandidatesContactDetails(ids));

				setCreatingContactDetailsRequest(false);
			},
		});
	}, [dispatch, displayedCandidates]);

	return (
		<div className={styles.headerInfoWrapper}>
			<div className={styles.left}>
				<Checkbox
					value={"Select All"}
					isChecked={candidateSelectionTypeForAssessment === "all"}
					partiallySelected={candidateSelectionTypeForAssessment === "partial"}
					onChange={() =>
						dispatch(
							handleAllCandidateSelectionForAssessment(
								candidateSelectionTypeForAssessment,
							),
						)
					}
				/>

				<ActionAltButton
					key={"expand"}
					customClass={classNames(styles.toggleExpandButton, {
						[styles.reverse]: displayAllExperience,
					})}
					iconComponent={<ExpandAllIcon />}
					handleClick={() => setDisplayAllExperience((prev) => !prev)}
					title={displayAllExperience ? "Collapse All" : "Expand All"}
				/>

				{hasActiveFilters && (
					<div className={styles.filterCountInfo}>
						<span>
							<FilterIcon height={20} width={20} />
						</span>{" "}
						Showing {currentDisplayedCount ?? 0} of {totalSavedPeopleCount} people...
					</div>
				)}
			</div>

			<div className={styles.right}>
				<div className={styles.buttonsGroup}>
					<ActionAltButton
						key={"assessment_view"}
						customClass={classNames(styles.viewButton, {
							[styles.active]:
								activeProjectReportViewOnCandidateCard === "assessment",
						})}
						iconComponent={<AssessmentIcon />}
						handleClick={() =>
							dispatch(setActiveProjectReportViewOnCandidateCard("assessment"))
						}
						title={"Assessment View"}
					/>
					<ActionAltButton
						key={"summary_view"}
						customClass={classNames(styles.viewButton, {
							[styles.active]: activeProjectReportViewOnCandidateCard === "summary",
						})}
						iconComponent={<SummaryViewIcon />}
						handleClick={() =>
							dispatch(setActiveProjectReportViewOnCandidateCard("summary"))
						}
						title={"Summary View"}
					/>
				</div>

				{shouldDisplayGenerateAllSummary && (
					<ActionAltButton
						key={"summary"}
						customClass={classNames(styles.generateSummaryButton, {
							[styles.loading]: generatingSummaryForAll,
						})}
						iconComponent={<MagicWandIcon />}
						handleClick={handleGenerateAllSummariesButtonClick}
						title={
							generatingSummaryForAll
								? "Generating Summary for All..."
								: "Generate Summary for All"
						}
						loading={generatingSummaryForAll}
					/>
				)}
				{shouldDisplayGenerateAllContactDetails && (
					<ActionAltButton
						key={"contact"}
						customClass={classNames(styles.generateSummaryButton, {
							[styles.loading]: generatingContactDetailsForAll,
						})}
						iconComponent={<BiPhone color={"#4E5555"} />}
						handleClick={handleGenerateAllContactDetailsButtonClick}
						title={
							generatingContactDetailsForAll
								? "Generating contact details for All..."
								: "Generate contact details for All"
						}
						loading={generatingContactDetailsForAll}
					/>
				)}
			</div>
		</div>
	);
};

export default SavedPeopleHeaderSection;
