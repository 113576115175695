import {Tooltip} from "react-tooltip";
import styles from "../report.module.scss";

const ImportReportTooltip = (props: {reason: string; status: string; url: string}) => {
	function capitalizeFirstWord(str: string) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	return (
		<Tooltip id={props.url} place="top" delayShow={0} className={styles.toolTip}>
			{props.reason && props.status.toLowerCase() !== "bad url" && (
				<p style={{maxWidth: "300px"}}>{capitalizeFirstWord(props.reason)}</p>
			)}

			{props.status.toLowerCase() === "bad url" && (
				<p style={{maxWidth: "300px"}}>This URL is invalid. Please check again</p>
			)}
		</Tooltip>
	);
};

export default ImportReportTooltip;
