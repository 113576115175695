import React, {useEffect, useRef, useState} from "react";
import styles from "./styles.module.scss";
import {DropdownProps} from "components/Inputs/Dropdown/type";
import classNames from "classnames";
import {useOnClickOutside} from "hooks";

const Dropdown = <T,>({
	options,
	placeholder = "Select an option",
	onSelect,
	getOptionLabel,
	dropdownButtonClass,
	defaultSelectedOption = null,
}: DropdownProps<T>) => {
	const ref = useRef<Nullable<HTMLDivElement>>(null);

	const [dropdownListPosition, setDropdownListPosition] = useState(40);

	const [dropdownListWidth, setDropdownListWidth] = useState(180);

	const [isOpen, setIsOpen] = useState(false);

	const [selectedOption, setSelectedOption] = useState<Nullable<T>>(defaultSelectedOption);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	const handleSelect = (option: T) => {
		setSelectedOption(option);
		setIsOpen(false);
		onSelect(option);
	};

	useOnClickOutside(ref, () => setIsOpen(false));

	useEffect(() => {
		if (ref?.current) {
			setDropdownListPosition(ref.current.clientHeight + 1);
			setDropdownListWidth(ref.current.clientWidth);
		}
	}, []);

	return (
		<div className={classNames(styles.dropdown)} ref={ref}>
			<button
				role="dropdown-button"
				className={classNames(
					styles.dropdownToggle,
					dropdownButtonClass && dropdownButtonClass,
				)}
				aria-label={isOpen ? "open" : "closed"}
				onClick={handleToggle}
			>
				{placeholder}
			</button>

			<ul
				className={classNames(styles.dropdownMenu, {
					[styles.open]: isOpen,
				})}
				style={{top: dropdownListPosition, width: dropdownListWidth}}
			>
				{options.map((option, index) => (
					<li
						key={index}
						data-testid={getOptionLabel(option)}
						onClick={() => handleSelect(option)}
						className={classNames(styles.dropdownItem, {
							[styles.selectedItem]:
								selectedOption &&
								getOptionLabel(selectedOption) === getOptionLabel(option),
						})}
					>
						{getOptionLabel(option)}
					</li>
				))}
			</ul>
		</div>
	);
};

export default Dropdown;
