import {
	ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT,
	CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT,
	CLEAR_SIMILAR_CANDIDATES_RESULTS,
	FLAG_CANDIDATE,
	GET_CANDIDATES_BY_FILTER_DONE,
	GET_CANDIDATES_BY_FILTER_INIT,
	GET_CANDIDATES_DONE,
	GET_CANDIDATES_FAIL,
	GET_CANDIDATES_INIT,
	GET_SIMILAR_CANDIDATES_DONE,
	GET_SIMILAR_CANDIDATES_FAIL,
	GET_SIMILAR_CANDIDATES_INIT,
	GET_SINGLE_CANDIDATE_DONE,
	GET_SINGLE_CANDIDATE_FAIL,
	GET_SINGLE_CANDIDATE_INIT,
	IMPORT_LINKEDIN_PROFILE_IN_PROGRESS,
	RESET_FILTERED_CANDIDATES,
	SEARCH_LINKEDIN_PROFILE_IN_PROGRESS_FOR_CURRENT_IMPORT,
	SET_IMPORT_LINKEDIN_PROFILE_INFO,
	SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT,
	SET_SELECTED_CANDIDATES_DONE,
	SET_SELECTED_CANDIDATES_INIT,
	SET_SIMILAR_CANDIDATES_WITH_PAGINATION,
} from "../mapXActionTypes";

export function setSelectedCandidatesDone(data) {
	return {type: SET_SELECTED_CANDIDATES_DONE, payload: data};
}

export function setSelectedCandidatesInit() {
	return {type: SET_SELECTED_CANDIDATES_INIT, payload: true};
}

export function getCandidatesInit() {
	return {type: GET_CANDIDATES_INIT};
}

export function getCandidatesDone(data) {
	return {type: GET_CANDIDATES_DONE, payload: data};
}

export function getCandidatesFail(error) {
	return {type: GET_CANDIDATES_FAIL, payload: error};
}

export function getCandidatesByFilterInit() {
	return {type: GET_CANDIDATES_BY_FILTER_INIT};
}

export function getCandidatesByFilterDone(data) {
	return {type: GET_CANDIDATES_BY_FILTER_DONE, payload: data};
}

export function getCandidatesByFilterFail(error) {
	return {type: GET_CANDIDATES_FAIL, payload: error};
}

export function getSimilarCandidatesInit() {
	return {type: GET_SIMILAR_CANDIDATES_INIT};
}

export function getSimilarCandidatesDone(payload) {
	return {type: GET_SIMILAR_CANDIDATES_DONE, payload};
}

export function getSimilarCandidatesFail(error) {
	return {type: GET_SIMILAR_CANDIDATES_FAIL, payload: error};
}

export function clearSimilarCandidatesResults() {
	return {type: CLEAR_SIMILAR_CANDIDATES_RESULTS};
}

export function setSimilarProfilesWithPagination(payload) {
	return {type: SET_SIMILAR_CANDIDATES_WITH_PAGINATION, payload};
}

export function getSingleCandidateInit() {
	return {type: GET_SINGLE_CANDIDATE_INIT};
}

export function getSingleCandidateDone(data) {
	return {type: GET_SINGLE_CANDIDATE_DONE, payload: data};
}

export function getSingleCandidateFail(error) {
	return {type: GET_SINGLE_CANDIDATE_FAIL, payload: error};
}

export function resetFilteredCandidates() {
	return {type: RESET_FILTERED_CANDIDATES};
}

export function flagCandidate(payload) {
	return {type: FLAG_CANDIDATE, payload};
}

// IMPORTING LINKEDIN PROFILE

export function importLinkedInCandidateInProgress(payload) {
	return {type: IMPORT_LINKEDIN_PROFILE_IN_PROGRESS, payload};
}

export function setImportLinkedInProfileInfo(payload) {
	return {type: SET_IMPORT_LINKEDIN_PROFILE_INFO, payload};
}

/**
 ** @param payload: {projectId: true|false}
 */

export function setSearchProgressCandidateByLinkedInForProject(payload) {
	return {type: SEARCH_LINKEDIN_PROFILE_IN_PROGRESS_FOR_CURRENT_IMPORT, payload};
}

/**
 ** @param payload: {id: projectId, data: object } object contains import information, status receives from BE
 */

export function setImportLinkedInProfileInfoForProject(payload) {
	return {type: SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT, payload};
}

/**
 ** @param projectId
 */

export function clearImportLinkedInProfileInfoForProject(projectId) {
	return {type: CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT, payload: projectId};
}

export function activeAddPeopleModalForProject(payload) {
	return {type: ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT, payload};
}
