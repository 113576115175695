import mapxProjectInsightsApi from "api/projectApi/projectStatsApi";
import axios from "axios";
import {successResponse} from "helpers/map";
import {noop} from "lodash";

import {
	getIndustriesBackgroundReportDone,
	getIndustriesBackgroundReportFailed,
	getIndustriesBackgroundReportInit,
	getIndustriesReportFailed,
	getIndustriesReportFetch,
	getIndustriesReportSuccess,
	getProjectStatsFailed,
	getProjectStatsInit,
	setCountryReport,
	setEthnicDiversityData,
	setGenderDiversityData,
	setJobFunctionDistributionData,
	setJobFunctionDistributionInit,
	setProjectFrequentlyHiredFromData,
	setProjectStats,
} from "./projectActions";

const cancelTokens = {};

export const getGenderDiversityData = (id, callback) => async (dispatch) => {
	let data;
	try {
		const res = await mapxProjectInsightsApi.getGenderDiversityReport(id);
		res.status === 409 ? (data = []) : (data = res.data);

		dispatch(setGenderDiversityData(data));
	} catch (e) {
		console.error(e);
		callback && callback();
	} finally {
		callback && callback();
	}
};

export const getEthnicDiversityData = (id, callback) => async (dispatch) => {
	let data;
	try {
		const res = await mapxProjectInsightsApi.getEthnicDiversityReport(id);
		res.status !== 200 ? (data = []) : (data = res.data);
		dispatch(setEthnicDiversityData(data));
	} catch (e) {
		console.error(e);
	} finally {
		callback && callback();
	}
};

export const getCountryReport = (id, callback) => async (dispatch) => {
	let data = [];

	try {
		const res = await mapxProjectInsightsApi.getCountryReport(id);
		res.status !== 200 ? (data = []) : (data = res.data.results);

		dispatch(setCountryReport(data));
	} catch (e) {
		console.error(e);
	} finally {
		callback && callback();
	}
};

export const getIndustryReportData = (id) => async (dispatch) => {
	dispatch(getIndustriesReportFetch());

	try {
		const requestKey = `GET_INDUSTRIES_REPORT_PROJECT`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		const response = await mapxProjectInsightsApi.getIndustriesReport(id, config);

		if (successResponse(response, 200)) {
			const data = response.data.results;

			dispatch(getIndustriesReportSuccess(data));
		}

		if (axios.isCancel()) {
			dispatch(getIndustriesReportFailed());
		}
	} catch (e) {
		console.error(e);

		dispatch(getIndustriesReportFailed());
	}
};

export const getJobFunctionDistributionReport = (id) => async (dispatch) => {
	try {
		dispatch(setJobFunctionDistributionInit());

		const response = await mapxProjectInsightsApi.getJobFunctionDistribution(id);

		if (successResponse(response, 200)) {
			const data = response.data.results;

			dispatch(setJobFunctionDistributionData(data));
		}
	} catch (e) {
		console.error(e);
	}
};

export const getProjectStats = (id) => async (dispatch) => {
	try {
		dispatch(getProjectStatsInit());

		const requestKey = `GET_STATISTICS_PROJECT`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		const response = await mapxProjectInsightsApi.getProjectStats(id, config);

		if (successResponse(response, 200)) {
			const data = response.data;

			dispatch(setProjectStats(data));
		}

		if (axios.isCancel()) {
			dispatch(getIndustriesReportFailed());
		}
	} catch (e) {
		console.error(e);

		dispatch(getProjectStatsFailed({e}));
	}
};

export const getProjectFrequentlyHiredFromData =
	(id, params, config, callback = noop) =>
	async (dispatch) => {
		try {
			const response = await mapxProjectInsightsApi.getProjectFrequentlyHiredFrom(
				id,
				params,
				config,
			);

			if (successResponse(response, 200)) {
				const data = response.data;

				dispatch(setProjectFrequentlyHiredFromData(data));
			}
		} catch (e) {
			console.error(e);
		} finally {
			callback && callback();
		}
	};

export const getIndustriesBackgroundReport = (project_id) => async (dispatch) => {
	try {
		dispatch(getIndustriesBackgroundReportInit());

		const response = await mapxProjectInsightsApi.getIndustriesBackgroundReport(project_id);

		if (successResponse(response, 200)) {
			const data = response.data.results;

			dispatch(getIndustriesBackgroundReportDone(data));
		}
	} catch (error) {
		dispatch(getIndustriesBackgroundReportFailed({error}));

		return {error};
	}
};
