import {useCallback, useEffect, useMemo, useState} from "react";

import type {FC} from "react";

import {useAppDispatch, useAppSelector, useCompanyIdParam, useProjectInsightsFilters} from "hooks";
import {CompanyCard, RoundedButton} from "components";
import {targetListCompaniesSelector} from "store/mapx/target-list/targetListSelectors";
import {getProjectFrequentlyHiredFromData} from "store/mapx/project/projectAsyncActions";
import {projectFrequentlyHiredFromDataSelector} from "store/mapx/project/projectSelectors";
import {
	addCompanyToTargetList,
	removeCompanyFromTargetList,
} from "store/mapx/target-list/targetListAsyncActions";

import styles from "./ProjectFreqHiredFrom.module.scss";
import NotEnoughData from "../NotEnoughData";

const ProjectFreqHiredFrom: FC = () => {
	const dispatch = useAppDispatch();
	const paramId = useCompanyIdParam();
	const data = useAppSelector(projectFrequentlyHiredFromDataSelector);
	const targetListCompanies = useAppSelector(targetListCompaniesSelector);

	// const {functionSpecialismOptions} = useOrgChartFilterOptions({
	// 	excludedFilterOptions: [
	// 		FilterOptions.Country,
	// 		FilterOptions.Keyword,
	// 		FilterOptions.Seniority,
	// 	],
	// });

	//                                                              @TODO: For reset filters
	const {modifiedSelectedFilters /*, resetFilters*/} = useProjectInsightsFilters();
	// const isResettable = useMemo(
	// 	() => Object.values(selectedFilters).some((filters) => !!filters.length),
	// 	[selectedFilters],
	// );

	const [loading, setLoading] = useState<boolean>(false);

	const handleShowMoreClick = useCallback(() => {
		setLoading(true);

		dispatch(
			getProjectFrequentlyHiredFromData(
				paramId,
				{
					page: data?.pagination?.page + 1,
					per_page: 9,
					...modifiedSelectedFilters,
				},
				null,
				() => setLoading(false),
			),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, paramId]);

	useEffect(() => {
		if (paramId) {
			setLoading(true);
			dispatch(
				getProjectFrequentlyHiredFromData(
					paramId,
					{
						per_page: 9,
						page: 1,
						...modifiedSelectedFilters,
					},
					null,
					() => setLoading(false),
				),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paramId]);

	const inTargetList = useMemo(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		() => new Set(targetListCompanies.map((company: any) => company?.id)),
		[targetListCompanies],
	);

	const addCompany = (companyId: number) => {
		dispatch(addCompanyToTargetList(companyId));
	};

	const removeCompany = (companyId: number) => {
		dispatch(removeCompanyFromTargetList(companyId));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const renderCards = data?.results?.map(({company}: any) => {
		const isSelectedItem = inTargetList.has(company?.id);

		return (
			<CompanyCard
				key={company?.id}
				company={company}
				isTooltipSelect={false}
				isSelectedItem={isSelectedItem}
				handleAddClick={() => addCompany(company?.id)}
				handleRemoveClick={() => removeCompany(company?.id)}
			/>
		);
	});

	return (
		<div className={styles.company_block}>
			<div className={styles.company_block__head}>
				<p className={styles.company_block__head_title}>Companies Frequently Hired From</p>

				{/* <MultiSelect
					type="once"
					itemsOnLeft
					hideRightIcon
					isScrollable={false}
					name="current_job_functions"
					title="Function & Specialism"
					subName="current_specialisms"
					onSelectChange={onSelectChange}
					data={functionSpecialismOptions}
					selectedFilters={selectedFilters}
					values={selectedFilters.current_job_functions}
					subValues={selectedFilters.current_specialisms}
					// @TODO: For reset filters
					// isResetShow
					// resetFilters={resetFilters}
					// isResettable={isResettable}
					// isSelected={
					// 	!!selectedFilters.current_job_functions.length ||
					// 	!!selectedFilters.current_specialisms.length
					// }
				/> */}
			</div>

			<hr className={styles.company_block__line} />

			<div className={styles.company_block__card}>{renderCards}</div>

			{!loading &&
				data?.pagination?.page !== data?.pagination?.pages &&
				!!data?.pagination?.count && (
					<div className={styles.company_block__show_more}>
						<RoundedButton onClick={handleShowMoreClick}>Show More</RoundedButton>
					</div>
				)}

			{!data?.pagination?.pages && (
				<NotEnoughData
					applySmallSize={false}
					title="There is not enough data to display companies frequently hired from report."
				/>
			)}
		</div>
	);
};

export default ProjectFreqHiredFrom;
