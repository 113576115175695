import type {TRootState} from "types";
import {createSelector} from "reselect";

export const refreshLinkedInCandidatesInProgressSelector = (state: TRootState) =>
	state.refresh_candidate.refreshLinkedInCandidatesInProgress;

export const currentRefreshLinkedInProfilesScrapeInfoSelector = (state: TRootState) =>
	state.refresh_candidate.currentRefreshLinkedInProfilesScrapeInfo;

export const getLinkedInUrl = (_: TRootState, url: string) => url;

export const isCurrentCurrentCandidateRefreshIsInProgressSelector = createSelector(
	[
		refreshLinkedInCandidatesInProgressSelector,
		currentRefreshLinkedInProfilesScrapeInfoSelector,
		getLinkedInUrl,
	],
	(allRefreshIsInProgress, currentRefreshLinkedInProfileScrapeInfo, linkedInUrl) => {
		return (
			!!(allRefreshIsInProgress && allRefreshIsInProgress[linkedInUrl] !== undefined) ||
			!!(
				currentRefreshLinkedInProfileScrapeInfo &&
				currentRefreshLinkedInProfileScrapeInfo[linkedInUrl] !== undefined
			)
		);
	},
);
