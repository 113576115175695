import {STProject} from "api/projectApi/types";
import {TAppDispatch} from "types";
import {
	setBulkImportStateFromBackgroundInProgressData,
	setImportLinkedInURLsListForCurrentImport,
	setShowLastImportReport,
} from "../linkedin-bulk-import/linkedInBulkImportActions";
import axios, {AxiosResponse, CancelToken, CancelTokenSource} from "axios";
import mapXBackgroundTaskAPI from "api/backgroundTaskApi";
import {
	setBackgroundBulkImportsInProgress,
	setLastCompleteImportLinkedInProfileInfoForProject,
	setProjectCandidateAssessmentProcess,
} from "./backgroundTaskActions";
import {
	backgroundTaskApiParameterOptions,
	STBackgroundTaskResponse,
	TImportData,
	TImportInfo,
	TImportObject,
	TImportUrlInfo,
} from "api/backgroundTaskApi/types";
import {successResponse} from "helpers/map";

const cancelTokens: {[key: string]: CancelTokenSource} = {};

const setBulkImportStates =
	(response: AxiosResponse<TImportInfo>, urls: string[], activeProjectInState: STProject) =>
	async (dispatch: TAppDispatch) => {
		const payload = {urls, activeProjectInState, response};
		dispatch(
			setImportLinkedInURLsListForCurrentImport({
				url: urls,
				id: activeProjectInState.id,
				targetListId: activeProjectInState.target_list_id,
				status: "In Progress",
			}),
		);

		dispatch(setBulkImportStateFromBackgroundInProgressData(payload));
	};

const handleInProgressImports =
	(
		inProgressImports: {id: number}[],
		activeProjectInState: STProject,
		config: {cancelToken: CancelToken},
	) =>
	async (dispatch: TAppDispatch) => {
		try {
			const response: AxiosResponse<TImportInfo> =
				await mapXBackgroundTaskAPI.getBackgroundTasksById(inProgressImports[0].id, config);

			if (response.status === 200) {
				const urls = response.data?.linkedin_candidate_urls.map(
					(u: TImportUrlInfo) => u.url,
				);

				// Dispatch the states to activate bulk import in watcher
				await dispatch(setBulkImportStates(response, urls, activeProjectInState));
			}

			dispatch(setBackgroundBulkImportsInProgress(false));
		} catch (error) {
			console.error("Error fetching in-progress import:", error);
		}
	};

// Utility function to map response data to import data object
const mapResponseToImportData = (
	response: AxiosResponse<TImportInfo>,
	activeProjectInState: STProject,
): TImportData => ({
	id: activeProjectInState.id,
	importInfo: response.data,
	status: response.data.status,
	projectInfo: activeProjectInState,
	targetListId: activeProjectInState.target_list_id,
});

// Function to handle URL statuses
const handleUrlStatuses = (urlInfos: TImportUrlInfo[]): TImportUrlInfo[] => {
	const notFinishedStateValues = [
		"waiting",
		"importing",
		"scraping",
		"in progress",
		"created",
		"scraped",
	];
	const scrapingErrorMessage =
		"This profile has been sent for manual import. If it is a valid URL, it will be visible on MapX within 12 hours";

	return urlInfos.map((urlInfo) => {
		if (notFinishedStateValues.includes(urlInfo.status.toLowerCase())) {
			urlInfo.status = "Scraping Error";
			urlInfo.reason = scrapingErrorMessage;
		} else if (["Import Error", "Scraping Error"].includes(urlInfo.status)) {
			urlInfo.reason = scrapingErrorMessage;
		} else if (urlInfo.status === "Not Found") {
			urlInfo.reason = "This profile was not found";
		}

		return urlInfo;
	});
};

// Function to process and accumulate completed imports data
const accumulateCompletedImportsData = (
	responses: AxiosResponse<TImportInfo>[],
	activeProjectInState: STProject,
): TImportObject[] => {
	const allImportData: TImportObject[] = [];

	responses.forEach((response) => {
		if (response.status === 200) {
			const responseData = response.data;
			responseData.linkedin_candidate_urls = handleUrlStatuses(
				responseData.linkedin_candidate_urls,
			);

			if (activeProjectInState.id === responseData.project_id) {
				const data = mapResponseToImportData(response, activeProjectInState);
				allImportData.push({id: responseData.project_id, data});
			}
		}
	});

	return allImportData;
};

// Function to merge data from all imports
const mergeImportData = (allImportData: TImportObject[]): TImportData => {
	const mergedData: TImportData = {
		id: allImportData[0].id,
		status: "Completed",
		targetListId: allImportData[0].data.targetListId,
		importInfo: {
			status: "",
			linkedin_candidate_urls: [],
		},
	};

	allImportData.forEach((item) => {
		mergedData.importInfo.linkedin_candidate_urls =
			mergedData.importInfo.linkedin_candidate_urls.concat(
				item.data.importInfo.linkedin_candidate_urls,
			);
	});

	return mergedData;
};

// Main function to handle completed imports
const handleCompletedImports =
	(
		completedImports: {id: number}[],
		activeProjectInState: STProject,
		config: {cancelToken: CancelToken},
	) =>
	async (dispatch: TAppDispatch) => {
		try {
			const importIDsForCurrentProject = completedImports.map((importItem) => importItem.id);

			const promises = importIDsForCurrentProject.map((importID) =>
				mapXBackgroundTaskAPI.getBackgroundTasksById(importID, config),
			);
			const responses = await Promise.all(promises);

			const allImportData = accumulateCompletedImportsData(responses, activeProjectInState);
			if (allImportData.length > 0) {
				const mergedData = mergeImportData(allImportData);
				const payload = {id: mergedData.id, data: mergedData};

				await dispatch(setLastCompleteImportLinkedInProfileInfoForProject(payload));
			}

			dispatch(setBackgroundBulkImportsInProgress(false));
		} catch (error) {
			console.error("Error fetching completed imports:", error);
		}
	};

export const getAllBackgroundBulkImports =
	(activeProjectInState: STProject) => async (dispatch: TAppDispatch) => {
		let inProgressImports: {id: number}[] = [];
		let completedImports: {id: number}[] = [];

		const requestKey = `GET_BACKGROUND_BULK_IMPORTS`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		dispatch(setBackgroundBulkImportsInProgress(true));

		if (activeProjectInState) {
			const payload: backgroundTaskApiParameterOptions = {
				projectId: activeProjectInState.id,
				status: ["Completed", "In Progress", "Created"],
				backgroundTaskType: ["Candidates Bulk Import", "Candidate Assessment"],
			};

			const pagination = {
				page: 1,
				perPage: 40,
			};

			try {
				const response = await mapXBackgroundTaskAPI.getAllBackgroundTaskRequests(
					payload,
					pagination,
					config,
				);

				if (successResponse(response, 200)) {
					const backgroundTasks: STBackgroundTaskResponse[] = response.data.results;

					const bulkImportTasks = backgroundTasks.filter(
						(p: STBackgroundTaskResponse) => p.type === "Candidates Bulk Import",
					);

					const inProgressAssessments = backgroundTasks.filter(
						(p: STBackgroundTaskResponse) => p.type === "Candidate Assessment",
					);

					if (bulkImportTasks.length > 0) {
						const response = dispatch(
							processBackgroundBulkImportsFromResponse(
								bulkImportTasks,
								activeProjectInState,
							),
						);
						inProgressImports = response.inProgressImports;
						completedImports = response.completedImports;
					} else {
						dispatch(setBackgroundBulkImportsInProgress(false));
					}

					if (inProgressAssessments.length > 0) {
						dispatch(
							processProjectCandidateInProgressAssessment(inProgressAssessments),
						);
					}
				} else {
					dispatch(setShowLastImportReport(false));

					dispatch(setBackgroundBulkImportsInProgress(false));
				}
			} catch (error) {
				console.error("Error fetching background bulk imports:", error);
			}
		}

		// Stop the loader after 15 seconds anyway
		setTimeout(() => {
			dispatch(setBackgroundBulkImportsInProgress(false));
		}, 1000 * 15);

		return [
			{
				inProgressImports: inProgressImports,
				completedImports: completedImports,
			},
		];
	};

export const processProjectCandidateInProgressAssessment =
	(tasks: STBackgroundTaskResponse[]) => async (dispatch: TAppDispatch) => {
		try {
			const inProgressTasks = tasks.filter(
				(p: STBackgroundTaskResponse) =>
					p.type === "Candidate Assessment" &&
					(p.status === "In Progress" || p.status === "Created"),
			);

			if (inProgressTasks?.length > 0) {
				const requestKey = `HANDLE_IN_PROGRESS_ASSESSMENTS`;

				if (requestKey in cancelTokens) {
					cancelTokens[requestKey].cancel("Operation canceled due to new request.");
				}

				cancelTokens[requestKey] = axios.CancelToken.source();

				const config = {
					cancelToken: cancelTokens[requestKey].token,
				};

				const latestInProgressTask = inProgressTasks[0];

				const data = (await dispatch(
					getBackgroundTaskInfoById(latestInProgressTask.id, config),
				)) as unknown as Nullable<STBackgroundTaskResponse>;

				if (data !== null) {
					dispatch(setProjectCandidateAssessmentProcess(latestInProgressTask));
				}
			}
		} catch (e) {
			console.error(e);
		}
	};

export const processBackgroundBulkImportsFromResponse =
	(bulkImportBackgroundTasks: STBackgroundTaskResponse[], project: STProject) =>
	async (dispatch: TAppDispatch) => {
		const inProgressImports = bulkImportBackgroundTasks.filter(
			(p: STBackgroundTaskResponse) =>
				p.type === "Candidates Bulk Import" &&
				(p.status === "In Progress" || p.status === "Created"),
		);

		const completedImports = bulkImportBackgroundTasks.filter(
			(c: STBackgroundTaskResponse) =>
				c.type === "Candidates Bulk Import" && c.status === "Completed",
		);

		const requestKey = `HANDLE_IN_PROGRESS_IMPORTS`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		if (inProgressImports.length > 0) {
			dispatch(setShowLastImportReport(false));

			await dispatch(handleInProgressImports(inProgressImports, project, config));
		} else if (completedImports.length > 0) {
			dispatch(setShowLastImportReport(true));

			await dispatch(handleCompletedImports(completedImports, project, config));
		}

		return {
			inProgressImports,
			completedImports,
		};
	};

export const getBackgroundTaskInfoById =
	(taskId: number, config = {}) =>
	async () => {
		try {
			const response = await mapXBackgroundTaskAPI.getBackgroundTasksById(taskId, config);

			if (response && successResponse(response, 200)) {
				return response.data;
			}
		} catch (error) {
			console.error("Error fetching in-progress import:", error);
		}

		return null;
	};
