import React from "react";
import {InfoIcon} from "assets/icons";
import SeniorityFilterHoverTooltip from "mapx-components/Accordion/Tooltips/SeniorityFilterHoverTooltip";

export default function SeniorityToolTip() {
	return (
		<div>
			<span data-tooltip-id={"seniority_filter_hover"}>
				<div style={{marginLeft: "5px"}} data-testid="info-icon">
					<InfoIcon />
				</div>
			</span>
			<SeniorityFilterHoverTooltip />
		</div>
	);
}
