import {type FC} from "react";
import {Tooltip} from "react-tooltip";
import styles from "./SeniorityFilterHoverTooltip.module.scss";

const SeniorityFilterHoverTooltip: FC = ({...props}) => {
	return (
		<Tooltip
			float={false}
			id={"seniority_filter_hover"}
			clickable={false}
			place="bottom"
			delayShow={0}
			className={styles.wrapper}
			{...props}
		>
			<div>
				<h4 data-testid="seniority-filter-hover">Seniority Levels</h4>
				<p>
					<strong>Board:</strong> CEO, Group CFO, Group CHRO, NED and SID
				</p>
				<p>
					<strong>ExCo:</strong> Members of the senior leadership team
				</p>
				<p>
					<strong>C-Level:</strong> Chief, CXO, SVP, Group and Global Directors
				</p>
				<p>
					<strong>C Level-1:</strong> VP, Director, EVP, Group Heads
				</p>
				<p>
					<strong>C Level-2:</strong> Head of
				</p>
				<p>
					<strong>C Level-3:</strong> Manager and Senior Manager
				</p>
				<p>
					<strong>Other:</strong> Not a seniority label listed above
				</p>
			</div>
		</Tooltip>
	);
};

export default SeniorityFilterHoverTooltip;
