import {
	ADD_CANDIDATE_TO_TARGET_LIST_DONE,
	LOGOUT,
	SET_AP_CANDIDATE_TARGETLIST_TOGGLE,
	SET_AP_LOCATIONS_FILTER_CONFIG,
	UPDATE_CANDIDATE_PROFILE_AFTER_REFRESH,
} from "../mapXActionTypes";
import {
	ADD_SEARCH_REQUEST_TO_THE_LIST,
	CLEAR_ACTIVE_SEARCH_REQUEST,
	CLEAR_AP_ACTIVE_RESULT,
	CLEAR_AP_FILTER,
	CLEAR_AP_WORKFLOW_STATE,
	CLEAR_PR_FILTER,
	DISPLAY_AP_RESULTS,
	DISPLAY_REFRESH_AP_RESULT,
	JOB_TITLE_SUGGESTIONS_LOADING,
	PROJECT_RESULTS_TAB_ACTIVE_CONTENT,
	REMOVE_CURRENT_SEARCH_REQUEST_ON_PW,
	SEARCH_REQUEST_DETAILS_LOADING,
	SET_ACTIVE_SEARCH_REQUEST,
	SET_ALL_SEARCH_REQUESTS,
	SET_AP_ACTIVE_RESULT,
	SET_AP_COMPANY_POSITION,
	SET_AP_COMPANY_TL_POSITION,
	SET_AP_COMPANY_TYPE_FILTER_CONFIG,
	SET_AP_FILTER,
	SET_AP_HEADCOUNT_FILTER_CONFIG,
	SET_AP_JOB_FUNCTION_FILTER_CONFIG,
	SET_AP_KEYWORD_FILTER_CONFIG,
	SET_AP_MORE_RESULT,
	SET_AP_MULTIPLE_FILTERS,
	SET_AP_REVENUE_FILTER_CONFIG,
	SET_MORE_REJECTED_PROFILES_RESULT,
	SET_PR_FILTER,
	SET_PR_FILTERS,
	SET_REJECTED_PROFILES_RESULT,
} from "./additionalProfilesActionTypes";

export const additionalProfilesInitialState = {
	ap_project: null,
	job_titles_suggestion_loading: false,
	pr_filters: {
		company_ids: [],
		candidate_id: null,
		country_ids: [],
		search_width: "Narrow", // Width | Narrow option
		selected_job_titles: [],
		job_title_suggestions: [],
	},

	ap_filters: {
		companies: [],
		previous_companies: [],
		current_companies: [],

		tl_companies: [],
		previous_tl_companies: [],
		current_tl_companies: [],

		countries_or: [],
		previous_countries_or: [],
		current_countries_or: [],

		regions_or: [],
		previous_regions_or: [],
		current_regions_or: [],

		gender_diversity_importance: null, // Low/Medium/High
		ethnic_diversity_importance: null, // Low/Medium/High
		ordering: "-is_recommended", // Low/Medium/High

		industries: [],
		current_industries: [],
		previous_industries: [],

		current_seniority: [],

		job_function_selection_tree: {
			current: {},
			previous: {},
			any: {},
		},

		specialisms_and: [],
		specialisms_or: [],
		specialisms_not: [],

		current_specialisms_and: [],
		current_specialisms_or: [],
		current_specialisms_not: [],

		previous_specialisms_and: [],
		previous_specialisms_or: [],
		previous_specialisms_not: [],

		text_keywords_or: [],
		text_keywords_and: [],
		text_keywords_not: [],

		current_text_keywords_or: [],
		current_text_keywords_and: [],
		current_text_keywords_not: [],

		previous_text_keywords_or: [],
		previous_text_keywords_and: [],
		previous_text_keywords_not: [],

		revenue: [],
		current_revenue: [],
		previous_revenue: [],

		company_size: [],
		current_company_size: [],
		previous_company_size: [],

		company_type: [],
		previous_company_type: [],
		current_company_type: [],
	},

	candidate_company_headcount_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
	},

	candidate_company_revenue_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
	},

	candidate_company_type_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
	},

	ap_candidate_location_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
	},

	candidate_job_function_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
		active_current_position_connect_logic: "or", // "and" | "or" | "not"
		active_previous_position_connect_logic: "or", // "and" | "or" | "not"
		active_any_position_connect_logic: "or", // "and" | "or" | "not"
	},

	candidate_keyword_filter_config: {
		active_position: "current", // "current" | "previous" | "any"
		active_current_position_connect_logic: "or", // "and" | "or" | "not"
		active_previous_position_connect_logic: "or", // "and" | "or" | "not"
		active_any_position_connect_logic: "or", // "and" | "or" | "not"
	},

	ap_filters_position: {
		companies: "current",
		target_list: "current",
		target_list_toggle: false,
	},

	display_refresh_ap_result: false,
	skip_intro_and_display_results: false,
	search_request_details_loading: false,

	ap_rejected_profiles_result: [],
	ap_rejected_profiles_pagination_info: null,

	ap_results: [],
	ap_results_pagination_info: null,

	all_search_requests: [], // holds all search requests -> for now only more_like_this
	active_search_request: null, // holds active search request
	active_project_results_tab_content: "search_results", // other options -> "rejected_profiles", "profile_request"

	search_requests_progress_watch: {}, // Here we keep all the services from all projects which is in progress
};

const additionalProfilesReducers = (state = additionalProfilesInitialState, action) => {
	switch (action.type) {
		case PROJECT_RESULTS_TAB_ACTIVE_CONTENT:
			return {...state, active_project_results_tab_content: action.payload}; // bool value

		case SET_AP_COMPANY_POSITION: {
			const position = action.payload;

			return {
				...state,
				ap_filters_position: {
					...state.ap_filters_position,
					companies: position,
				},
			};
		}

		case SET_AP_COMPANY_TL_POSITION: {
			const position = action.payload;

			return {
				...state,
				ap_filters_position: {
					...state.ap_filters_position,
					target_list: position,
				},
			};
		}

		case SET_AP_CANDIDATE_TARGETLIST_TOGGLE:
			return {
				...state,
				ap_filters_position: {
					...state.ap_filters_position,
					target_list_toggle: action.payload,
				},
			};

		case JOB_TITLE_SUGGESTIONS_LOADING: {
			return {
				...state,
				job_titles_suggestion_loading: action.payload || false,
			};
		}
		// PROFILE REQUEST STATE MANAGE
		case SET_PR_FILTER:
			if (action.payload.type === undefined && action.payload.values === undefined) {
				return {...state};
			}

			return {
				...state,
				pr_filters: {...state.pr_filters, [action.payload.type]: action.payload.values},
			};

		case SET_PR_FILTERS:
			return {
				...state,
				pr_filters: action.payload, // sets array
			};
		case CLEAR_PR_FILTER:
			return {
				...state,
				pr_filters: {...additionalProfilesInitialState.pr_filters},
			};

		case ADD_CANDIDATE_TO_TARGET_LIST_DONE: {
			const {candidate} = action.payload;

			return {
				...state,
				ap_results: state.ap_results
					? state.ap_results.filter((item) => item.id !== candidate.id)
					: state.ap_results,
			};
		}
		case SET_AP_ACTIVE_RESULT:
			return {
				...state,
				ap_results: action.payload.results, // sets array
				ap_results_pagination_info: action.payload.pagination, // sets pagination info
			};
		case SET_AP_MORE_RESULT:
			return {
				...state,
				ap_results: [...state.ap_results, ...action.payload.results], // adds to array
				ap_results_pagination_info: action.payload.pagination, // sets pagination info
			};

		// ADDITIONAL PROFILE FILTER STATE MANAGE

		case SET_AP_JOB_FUNCTION_FILTER_CONFIG:
			return {
				...state,
				candidate_job_function_filter_config: {
					...state.candidate_job_function_filter_config,
					...action.payload,
				},
			};

		case SET_AP_KEYWORD_FILTER_CONFIG:
			return {
				...state,
				candidate_keyword_filter_config: {
					...state.candidate_keyword_filter_config,
					...action.payload,
				},
			};

		case SET_AP_HEADCOUNT_FILTER_CONFIG:
			return {
				...state,
				candidate_company_headcount_filter_config: {
					...state.candidate_company_headcount_filter_config,
					...action.payload,
				},
			};

		case SET_AP_REVENUE_FILTER_CONFIG:
			return {
				...state,
				candidate_company_revenue_filter_config: {
					...state.candidate_company_revenue_filter_config,
					...action.payload,
				},
			};

		case SET_AP_COMPANY_TYPE_FILTER_CONFIG:
			return {
				...state,
				candidate_company_type_filter_config: {
					...state.candidate_company_type_filter_config,
					...action.payload,
				},
			};

		case SET_AP_FILTER:
			if (action.payload.attribute === undefined && action.payload.value === undefined) {
				return {...state};
			}

			return {
				...state,
				ap_filters: {...state.ap_filters, [action.payload.attribute]: action.payload.value},
			};

		case SET_AP_MULTIPLE_FILTERS:
			return {
				...state,
				ap_filters: {
					...state.ap_filters,
					...action.payload,
				}, // updates multiple filterOptionsApi, keeps the unchanged filters
			};

		case CLEAR_AP_FILTER:
			return {...state, ap_filters: {...additionalProfilesInitialState.ap_filters}};

		case CLEAR_AP_ACTIVE_RESULT:
			return {
				...state,
				ap_results: [],
				ap_results_pagination_info: null,
				// ap_filters: {...additionalProfilesInitialState.ap_filters},
			};

		case DISPLAY_REFRESH_AP_RESULT:
			return {...state, display_refresh_ap_result: action.payload};

		case SET_REJECTED_PROFILES_RESULT:
			return {
				...state,
				ap_rejected_profiles_result: action.payload.results, // sets array
				ap_rejected_profiles_pagination_info: action.payload.pagination, // sets pagination info
			};

		case SET_MORE_REJECTED_PROFILES_RESULT:
			return {
				...state,
				ap_rejected_profiles_result: [
					...state.ap_rejected_profiles_result,
					...action.payload.results,
				], // adds to array
				ap_rejected_profiles_pagination_info: action.payload.pagination, // sets pagination info
			};

		case DISPLAY_AP_RESULTS:
			return {
				...state,
				skip_intro_and_display_results: true,
			};

		case REMOVE_CURRENT_SEARCH_REQUEST_ON_PW:
			let updated_spw = {...state.search_requests_progress_watch};
			const pw_id = action.payload;

			if (pw_id in updated_spw) {
				delete updated_spw[pw_id];
			}

			return {
				...state,
				search_requests_progress_watch: {...updated_spw},
			};

		case SET_ALL_SEARCH_REQUESTS:
			return {
				...state,
				all_search_requests: action.payload,
				active_project_results_tab_content: action.payload?.length
					? state.active_project_results_tab_content
					: "profile_request",
			};

		case ADD_SEARCH_REQUEST_TO_THE_LIST:
			const exists = state.all_search_requests.find((sr) => sr.id === action.payload.id);

			let searchList = [];

			if (exists) {
				searchList = [...state.all_search_requests].map((sr) => {
					if (sr.id === action.payload.id) {
						return action.payload;
					} else {
						return sr;
					}
				});
			} else {
				searchList = [action.payload, ...state.all_search_requests];
			}

			return {
				...state,
				all_search_requests: searchList,
			};

		case SET_ACTIVE_SEARCH_REQUEST:
			const searchRequestData = action.payload;

			let updatedSearchRequestsWatchers = {...state.search_requests_progress_watch};

			const isSearchRequestRunning =
				searchRequestData.status === "Created" ||
				searchRequestData.status === "In Progress";

			let skipIntroDisplayResult = false;

			const isSearchRequestFinished =
				searchRequestData.status === "Completed" ||
				searchRequestData.status === "Error" ||
				searchRequestData.status === "Canceled";

			if (isSearchRequestRunning) {
				updatedSearchRequestsWatchers = {
					...updatedSearchRequestsWatchers,
					[searchRequestData.id]: searchRequestData,
				};
				skipIntroDisplayResult = true; // we want to check the results directly
			} else if (
				isSearchRequestFinished &&
				updatedSearchRequestsWatchers[searchRequestData.id] !== undefined
			) {
				delete updatedSearchRequestsWatchers[searchRequestData.id];
			}

			const updatedAllSearchRequest = [...state.all_search_requests].map((item) => {
				if (searchRequestData.id === item.id) {
					item = {...searchRequestData};
				}

				return item;
			});

			return {
				...state,
				active_search_request: searchRequestData,
				search_requests_progress_watch: updatedSearchRequestsWatchers,
				skip_intro_and_display_results: skipIntroDisplayResult,
				all_search_requests: [...updatedAllSearchRequest],
				active_project_results_tab_content: "search_results",
			};

		case SEARCH_REQUEST_DETAILS_LOADING: {
			return {
				...state,
				search_request_details_loading: action.payload,
			};
		}

		case CLEAR_ACTIVE_SEARCH_REQUEST: {
			return {
				...state,
				active_search_request: null,
			};
		}

		case UPDATE_CANDIDATE_PROFILE_AFTER_REFRESH:
			return {
				...state,
				ap_results: [
					...state.ap_results.map((c) => {
						if (c.id === action.payload.id) {
							return action.payload;
						} else return c;
					}),
				],
			};

		case SET_AP_LOCATIONS_FILTER_CONFIG:
			return {
				...state,
				ap_candidate_location_filter_config: {
					...state.ap_candidate_location_filter_config,
					...action.payload,
				},
			};

		case CLEAR_AP_WORKFLOW_STATE:
			// we cant reset it as we watch progress globally
			return {
				...state,
				...additionalProfilesInitialState,
				search_requests_progress_watch: {...state.search_requests_progress_watch},
			};
		case LOGOUT:
			return {
				...state,
				...additionalProfilesInitialState,
			};
		default:
			return state;
	}
};

export default additionalProfilesReducers;
