import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import styles from "../searchByLinkedIn.module.scss";
import {isLinkedinProfileUrl, linkedInUrlPurify} from "helpers/string";
import {Button, ResetFilters, ToastContent} from "components";
import type {TFormProps} from "./types";
import classNames from "classnames";
import {BiRefresh} from "react-icons/bi";
import DownloadIcon from "assets/icons/Download";
import Papa from "papaparse";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {setImportLinkedInURLsListForCurrentImport} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";
import {useAppDispatch, useAppSelector} from "hooks";
import UploadIcon from "assets/icons/Upload";
import {toast} from "react-toastify";
import {CloseIcon} from "assets/icons";

const Form: React.FC<TFormProps> = ({
	handleSubmit,
	inProgress,
	importedLinkedInUrls,
	linkedInUrl,
	handleDownload,
	source,
}) => {
	const dispatch = useAppDispatch();

	const [fileName, setFileName] = useState<string>("");

	const [userTyping, setUserTyping] = useState(false);

	const [input, setInput] = useState("");

	const [inputArray, setInputArray] = useState<string[]>([]);

	const project = useAppSelector(projectSelector);

	const hasErrorRef = useRef<string[]>([]);

	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newValue = e.target.value.replace(/\n/g, " ");

		const result = linkedInUrlPurify(newValue);

		const validURLs = getValidURLs(result);

		validURLs.map((url) => {
			if (!inputArray.includes(url)) {
				setInput((prevInput) => prevInput + (prevInput ? "\n" : "") + url);
				setInputArray((prev) => [...prev, url]);
				// inputArray.push(url)
			}
		});
	};

	const getValidURLs = (inputURLs: string) => {
		setUserTyping(false);

		const separatedValues = inputURLs?.split(/(https?:\/\/)/);

		let result = separatedValues.shift() || "";

		for (const element of separatedValues) {
			if (element.startsWith("https://") || element.startsWith("http://")) {
				result += "\n";
			}
			result += element;
		}

		// Add newline character at the end
		result += "\n";

		const urlsArray = result
			.split("\n")
			.map((url) => url.trim())
			.filter((url: string) => url !== "");

		const uniqueUrls = Array.from(new Set(urlsArray));

		hasErrorRef.current = uniqueUrls.filter((url: string) => !isLinkedinProfileUrl(url));

		const duplicates = uniqueUrls.filter((url: string) => inputArray.includes(url));

		const toastTitle =
			hasErrorRef.current.length > 0
				? "Invalid URL filtered out"
				: duplicates.length > 0
				? "Duplicate URL filtered out"
				: duplicates.length > 0 &&
				  hasErrorRef.current.length > 0 &&
				  "Invalid, & duplicate URLs filtered out";

		(hasErrorRef.current.length > 0 || duplicates.length > 0) &&
			toast.warning(ToastContent, {
				autoClose: 3000,
				closeOnClick: true,
				data: {
					title: toastTitle,
				},
				delay: 0,
			});

		textareaRef?.current?.focus();

		return uniqueUrls.filter((url: string) => isLinkedinProfileUrl(url));
	};

	const submit = () => {
		handleSubmit(inputArray);
	};

	const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];

		if (file) {
			setFileName(file.name);
		}

		if (
			file &&
			file.type !== "application/vnd.ms-excel" &&
			file.name.split(".").pop() !== "csv"
		) {
			toast.warning(ToastContent, {
				autoClose: 3000,
				closeOnClick: true,
				data: {
					title: "Please upload a CSV file",
				},
				delay: 0,
			});

			return; // Exit early if the file is not a CSV file
		}

		const reader = new FileReader();

		reader.onload = () => {
			const result = reader.result;
			// eslint-disable-next-line
			// @ts-ignore
			const data = Papa.parse(result, {header: true}).data;

			// Check if the 'linkedin_url' column exists in the uploaded file
			const linkedinUrlExists = data.some((row: {linkedin_url: string}) =>
				Object.keys(row).includes("linkedin_url"),
			);

			if (!linkedinUrlExists) {
				toast.warning(ToastContent, {
					autoClose: 3000,
					closeOnClick: true,
					data: {
						title: "File contains no entry named linkedin_url",
					},
					delay: 0,
				});

				return; // Exit early if 'linkedin_url' column does not exist
			}
			if (data.length < 2) {
				toast.warning(ToastContent, {
					autoClose: 3000,
					closeOnClick: true,
					data: {
						title: "File contain no URL's",
					},
					delay: 0,
				});

				return; // Exit early if 'linkedin_url' column does not have any entry
			}

			const extractedUrls = data
				.filter((row: {linkedin_url: string}) => row.linkedin_url?.trim() !== "")
				.map((row: {linkedin_url: string}) => {
					const trimmedUrl = row.linkedin_url?.trim();
					const parts = trimmedUrl.split("?")[0].split("/");

					return parts.slice(0, 5).join("/");
				});

			const formattedUrls = extractedUrls.join("\n");

			const validURLs = getValidURLs(formattedUrls);

			validURLs.map((url) => {
				if (!inputArray.includes(url)) {
					setInput((prevInput) => prevInput + (prevInput ? "\n" : "") + url);
					setInputArray((prev) => [...prev, url]);
					// inputArray.push(url)
				}
			});
		};

		if (file) {
			reader.readAsText(file);
		}
	};

	useEffect(() => {
		// saving the input to global state
		if (inputArray.length > 0) {
			dispatch(
				setImportLinkedInURLsListForCurrentImport({
					url: inputArray,
					id: source === "project" ? project?.id : 0,
					targetListId: project?.target_list_id,
					status: "waiting",
				}),
			);
		}
	}, [inputArray, dispatch, project, source]);

	const handleRemoveUrl = (index: number, url: string) => {
		setUserTyping(false);

		const newArray = [...inputArray];

		newArray.splice(index, 1);

		setInputArray(newArray);

		const regex = new RegExp(`(${url.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`, "g");

		const filteredInput = input.replace(regex, "");

		setInput(filteredInput.trim());

		dispatch(
			setImportLinkedInURLsListForCurrentImport({
				url: newArray,
				id: project?.id,
				targetListId: project?.target_list_id,
				status: "waiting",
			}),
		);

		if (inputArray.length < 7) {
			textareaRef?.current?.focus();
		}
	};

	useEffect(() => {
		// if stored linkedin value exists in global state, assign it to the form input
		if (linkedInUrl) {
			const urls = linkedInUrl.map((item) => item.url);
			if (inputArray.length === 0) {
				setInputArray(urls);
			}
		}
	}, [linkedInUrl, inputArray.length]);

	const downloadCSV = () => {
		const csvUrl = window.location.origin + "/files/linkedIn_import_template.csv";

		const link = document.createElement("a");
		link.href = csvUrl;
		link.setAttribute("download", "linkedIn_import_template.csv");
		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};

	const handleResetClick = () => {
		setInputArray([]);

		setInput("");

		setFileName("");

		dispatch(
			setImportLinkedInURLsListForCurrentImport({
				url: [],
				id: project?.id,
				targetListId: project?.target_list_id,
				status: "waiting",
			}),
		);

		textareaRef?.current?.focus();
	};

	const isItActionClick = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		return event.altKey || event.ctrlKey || (event.metaKey && event.key === "v");
	};

	const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
		if (!isItActionClick(event)) {
			setUserTyping(true);

			event.preventDefault();
		}
	};

	const calculateTextareaHeight = () => {
		switch (inputArray.length) {
			case 0:
				return "220px";
			case 1:
				return "160px";
			case 2:
				return "110px";
			case 3:
				return "90px";
			case 4:
				return "26px";
			default:
				return "30px";
		}
	};

	return (
		<div className={styles.wrapper__form}>
			<div className={styles.wrapper__title}>Add Profile</div>

			<div className={styles.wrapper__subtitle}>
				<p>LinkedIn URLs</p>
				<div className={styles.resetArea} data-testid="clearAll">
					{inputArray?.length > 0 && (
						<ResetFilters
							parentStyle={{color: "#5A5A5A", marginRight: 19}}
							onClick={handleResetClick}
							displayIcon={true}
						>
							Clear All
						</ResetFilters>
					)}
				</div>
			</div>
			{
				<div className={styles.form_container} data-testid="removeUrlDiv">
					{inputArray?.map((url, index) => (
						<div key={index} className={styles.linkedinTag}>
							<p>
								{url}
								<span>
									<CloseIcon
										title={"Remove this URL"}
										color={"#837D7C"}
										onClick={() => handleRemoveUrl(index, url)}
									/>
								</span>
							</p>
						</div>
					))}
					<textarea
						style={{height: calculateTextareaHeight()}}
						ref={textareaRef}
						value=""
						rows={6}
						className={styles.textAreaForPaste}
						placeholder="Paste linkedin URLs here"
						onChange={handleOnChange}
						onKeyDown={handleKeyDown}
						disabled={inProgress}
					/>
				</div>
			}

			<small style={{color: "#b33636"}} hidden={!userTyping}>
				You can only paste linkedin URLs here
			</small>
			<p style={{textAlign: "center", color: "#888888"}}> or</p>
			<p style={{fontSize: "15px", color: "#888888"}}>Upload CSV file</p>

			<div className={styles.custom_file_upload}>
				<input
					type="file"
					id="file-upload"
					className={styles.input_file}
					onChange={handleFileUpload}
				/>
				<label htmlFor="file-upload" className={styles.file_label}>
					<div className={styles.left_half}>
						<span className={styles.placeholder}>
							{fileName
								? fileName.slice(0, 40) + (fileName.length > 35 ? "..." : "")
								: "Upload your document..."}
						</span>
					</div>
					<div className={styles.right_half}>
						<UploadIcon /> Upload File
					</div>
				</label>
			</div>

			<div className={styles.downloadTemplate}>
				<a onClick={downloadCSV}>
					<DownloadIcon /> <span>Download the CSV template</span>
				</a>

				{importedLinkedInUrls && importedLinkedInUrls.length > 0 && (
					<a
						onClick={() => handleDownload(importedLinkedInUrls)}
						data-testid="DownloadBulkImportCsvButton"
					>
						<DownloadIcon /> <span>Download previous imports</span>
					</a>
				)}
			</div>

			<Button
				data-testid="SubmitImport"
				name={"Add Profile"}
				type={"button"}
				className={classNames(styles.button, styles.wrapper__form__btn)}
				onClick={submit}
				disabled={inputArray.length === 0 || inProgress}
			>
				{inProgress && <BiRefresh size={22} className={classNames(styles.refresh)} />}{" "}
				{inProgress ? `Importing Profiles...` : `Start Importing`}
			</Button>
		</div>
	);
};

export default Form;
