import HttpClient from "api/index";
import {TProjectCandidateRecommendedStatusForm} from "api/projectApi/projectCandidatesApi/types";

class ProjectCandidateApi extends HttpClient {
	async changeProjectCandidateRecommendedStatus(
		projectId: number,
		candidateId: number[],
		formBody: TProjectCandidateRecommendedStatusForm,
		config = {},
	) {
		return this.doPatch(
			`/projects/${projectId}/candidates/${candidateId.join(",")}`,
			formBody,
			config,
		);
	}
}

const projectCandidateApi = new ProjectCandidateApi();

export default projectCandidateApi;
