import {useEffect, useCallback, useState} from "react";
import {useSelector} from "react-redux";
import classNames from "classnames";
import useInfiniteScroll from "react-infinite-scroll-hook";
import type {ChangeEvent, SyntheticEvent} from "react";

import colors from "styles/themes.module.scss";
import {RecycleBin} from "assets/icons";
import {useAppDispatch, useModalHook, useSearchDebounce} from "hooks";
import {AutoComplete} from "mapx-components";
import GeneralButton from "mapx-components/Buttons/GeneralButton";
import {
	CompanyItem,
	Message,
	ModalHeader,
	ModalSeparator,
	SelectWithTooltip,
	Switch,
	TextInput,
} from "components";
import {searchedCompaniesInProgressSelector} from "store/mapx/filter/filterSelectors";
import {getSearchedCompaniesData} from "store/mapx/filter/filterAsyncActions";
import {clearSearchedCompanies} from "store/mapx/filter/filterActions";
import {EMPTY_COMPANY_LOGO} from "mapx-constants";
import {updateMarketMap} from "store/mapx/market-map/marketMapAsyncFunctions";
import {
	creationErrorSelector,
	creationInProgressSelector,
} from "store/mapx/market-map/marketMapSelectors";
import {resetMarketMapError} from "store/mapx/market-map/marketMapActions";
import {targetListCompaniesSelector} from "store/mapx/target-list/targetListSelectors";
import type {TRootState} from "types";
import type {STCompany} from "api/companyApi/types";

import styles from "./editMarketMapModal.module.scss";
import ImportFromProjectModal from "../ImportFromProjectModal";
import type {TEditMarketMapModal} from "./types";
import ImportFromTargetListModal from "../ImportFromTargetListModal";
import {userSelector} from "store/mapx/user/userSelectors";

const EditMarketMapModal = ({
	modalProps: marketMap,
	setModalDisplay,
	modalDisplay = true,
}: TEditMarketMapModal) => {
	const dispatch = useAppDispatch();

	const [name, setName] = useState(marketMap.name);
	const [description, setDescription] = useState(marketMap.description || "");
	const [companies, setCompanies] = useState<STCompany[]>(marketMap.companies || []);
	const [global, setGlobal] = useState(marketMap.is_global);

	const [showImportFromProjectModal, setShowImportFromProjectModal] = useState(false);
	const [showImportFromTargetListModal, setShowImportFromTargetListModal] = useState(false);

	const [validation, setValidation] = useState({
		field: "",
		message: "",
	});

	const max500 = companies.length > 500;

	const toggleGlobal = useCallback(() => setGlobal(!global), [global]);

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{content: {width: "640px", borderRadius: "8px", overflow: "visible"}},
		modalDisplay,
		colors.mainThemeColor,
	);

	const [searchTerm, setSearchTerm] = useSearchDebounce(800);

	const searchedCompanies = useSelector((state: TRootState) => state.filter.searchedCompanies);

	const loading = useSelector(searchedCompaniesInProgressSelector);

	const submitting = useSelector(creationInProgressSelector);

	const error = useSelector(creationErrorSelector);

	const targetListCompanies = useSelector(targetListCompaniesSelector);

	const user = useSelector(userSelector);

	const searchCompanies = useCallback(
		(companyName: string, page: number) => {
			if (companyName && companyName.length >= 2) {
				dispatch(getSearchedCompaniesData(companyName, page));
			}
		},
		[dispatch],
	);

	const handleImportCompanies = useCallback(
		(importedCompanies: STCompany[]) => {
			const existingIds = new Set(companies.map((company) => company.id));

			setCompanies([
				...importedCompanies.filter((company) => !existingIds.has(company.id)),
				...companies,
			]);
		},
		[companies],
	);

	const [loadingRef] = useInfiniteScroll({
		loading,
		hasNextPage: searchedCompanies?.pagination?.pages > searchedCompanies?.pagination?.page,
		onLoadMore: () => searchCompanies(searchTerm, searchedCompanies?.pagination?.page + 1 || 1),
		rootMargin: "0px 0px 400px 0px",
	});

	const handleItemClick = useCallback(
		(item: STCompany) => {
			// Do not add if already exists
			if (companies.find((company) => company.id === item.id)) return;

			setCompanies([item, ...companies]);
		},
		[companies],
	);

	const handleItemRemove = useCallback(
		(item: STCompany) => {
			setCompanies(companies.filter((company) => company.id !== item.id));
		},
		[companies],
	);

	const handleSubmit = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();

			if (!name.trim()) {
				setValidation({
					message: "Please enter a valid name for the target List.",
					field: "name",
				});

				return;
			}

			dispatch(
				updateMarketMap(
					marketMap.id,
					name.trim(),
					global,
					companies.map((company) => company.id),
					description.trim(),
				),
			);
		},
		[companies, description, dispatch, global, marketMap, name],
	);

	const handleNameChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setName(e.target.value);

			if (error?.status === 409) {
				dispatch(resetMarketMapError());
			}
		},
		[dispatch, error],
	);

	const handleDescriptionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setDescription(e.target.value);
	}, []);

	const handleImportFromTargetList = useCallback(() => {
		handleImportCompanies(targetListCompanies);
	}, [handleImportCompanies, targetListCompanies]);

	const handleRemoveAllCompanies = useCallback(() => {
		setCompanies([]);
	}, []);

	useEffect(() => {
		dispatch(clearSearchedCompanies());

		if (searchTerm) {
			searchCompanies(searchTerm, 1);
		}
	}, [searchTerm, dispatch, searchCompanies]);

	return (
		<>
			<Modal
				title="Edit Target List"
				style={customStyles}
				isOpen={modalIsOpen}
				contentLabel="Modal"
				onRequestClose={() => setModalDisplay(false)}
			>
				<ModalHeader title="Edit Target List" onClose={() => setModalDisplay(false)} />
				<ModalSeparator />
				<form className={styles.content} onSubmit={handleSubmit}>
					<TextInput
						label="Name"
						maxLength={255}
						name="name"
						onChange={handleNameChange}
						placeholder="Type a name"
						required
						type="text"
						value={name}
						errorText={
							error?.status === 409
								? error.message
								: validation.field === "name"
								? validation.message
								: undefined
						}
					/>
					<TextInput
						customClass={styles.description}
						label="Description"
						maxLength={255}
						name="description"
						placeholder="Type a description"
						type="text"
						value={description}
						onChange={handleDescriptionChange}
					/>
					{user?.user_id === marketMap.owner_id && (
						<>
							<label className={styles.label}>Share as MapX</label>
							<Switch checked={global} onChange={toggleGlobal} />
							<span className={styles.global}>{global ? "Active" : "Inactive"}</span>
						</>
					)}
					<div className={styles.line} />
					<div className={styles.row}>
						<AutoComplete
							// @TODO: Investigate why it shows error
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							handleFilterChange={setSearchTerm}
							handleItemClick={handleItemClick}
							hasMore={loading}
							isLoading={loading}
							ref={loadingRef}
							notFoundResult="No company found"
							SuggestionListItemComponent={CompanyItem}
							suggestions={searchedCompanies.results}
							margin="0"
						/>
						<SelectWithTooltip
							className={styles.select}
							placeholder="Import"
							options={[
								{
									label: "From Target List",
									action: handleImportFromTargetList,
								},
								{
									label: "From Project",
									action: () => setShowImportFromProjectModal(true),
								},
							]}
						/>
					</div>
					{max500 && (
						<Message
							message="You can add max 500 companies per Target List."
							className={styles.message}
							level="error"
						/>
					)}
					<div className={styles.companies}>
						<div className={classNames(styles.title, styles.label)}>
							Added Companies
							<div className={styles.count}>{companies.length}</div>
							<div className={styles.removeAll} onClick={handleRemoveAllCompanies}>
								<RecycleBin />
								Remove all
							</div>
						</div>
						{companies.length > 0 ? (
							<div className={styles.list}>
								{companies.map((company) => (
									<div key={company.id.toString()} className={styles.company}>
										<img
											src={company.logo_url || EMPTY_COMPANY_LOGO}
											onError={(e) => {
												e.currentTarget.src = EMPTY_COMPANY_LOGO;
											}}
											className={styles.logo}
										/>
										<div className={styles.name}>{company.name}</div>
										<div
											className={styles.delete}
											onClick={() => handleItemRemove(company)}
										>
											<RecycleBin />
										</div>
									</div>
								))}
							</div>
						) : (
							<div className={styles.empty}>No companies added</div>
						)}
					</div>
					<GeneralButton
						disabled={max500 || companies.length === 0}
						customClass={styles.button}
						loadingSave={submitting}
						title="Update Target List"
					/>
				</form>
			</Modal>
			<ImportFromProjectModal
				modalDisplay={showImportFromProjectModal}
				setModalDisplay={setShowImportFromProjectModal}
				setCompanies={handleImportCompanies}
			/>
			<ImportFromTargetListModal
				modalDisplay={showImportFromTargetListModal}
				setModalDisplay={setShowImportFromTargetListModal}
				setCompanies={handleImportCompanies}
			/>
		</>
	);
};

export default EditMarketMapModal;
