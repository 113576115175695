import {
	ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT,
	ACTIVE_IMPORT_PERCENTAGE,
	CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_IMPORT_BY_ID,
	DELETE_LINKEDIN_PROFILES_IN_PROGRESS,
	IMPORT_LINKEDIN_PROFILE_IN_PROGRESS,
	IMPORT_LINKEDIN_PROFILES_IN_PROGRESS,
	SEARCH_LINKEDIN_PROFILE_IN_PROGRESS_FOR_CURRENT_IMPORT,
	SET_BACKGROUND_BULK_IMPORTS_IN_PROGRESS_TO_WATCHER,
	SET_BULK_IMPORT_ID_FOR_COMPANY,
	SET_CURRENT_IMPORT_FINISHED,
	SET_IMPORT_LINKEDIN_PROFILE_INFO,
	SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_COMPANY,
	SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT,
	SET_SHOW_IMPORT_REPORT,
	SET_SHOW_LAST_IMPORT_REPORT,
	SET_SHOW_LAST_IMPORT_REPORT_FOR_COMPANY,
	UPDATE_LINKEDIN_PROFILES_IN_PROGRESS,
} from "../mapXActionTypes";
import {
	TProfilesImportInProgress,
	TSetImportLinkedinProfileInfoForCurrentImportPayload,
} from "mapx-components/AddPeopleByLinkedIn/types";
import {LinkedinBulkImportReducerTypes} from "store/mapx/linkedin-bulk-import/types";
import {AnyAction} from "redux";

const initialState: LinkedinBulkImportReducerTypes = {
	showLastBulkImportReport: false,
	showBulkImportReport: false,
	showLastBulkImportReportForCompany: false,
	importLinkedInCandidateInProgress: false,
	isLastBulkImportReportDataLoading: false,
	isCurrentImportFinished: false,
	importLinkedInCandidatesInProgress: [],
	importLinkedInProfileInfo: null,
	candidateImportByLinkedInForProject: {}, // used for projectApi // Structure projectId: Info
	candidateImportByLinkedInForCompany: {}, // used for projectApi // Structure projectId: Info
	searchCandidateForProject: {}, // projectId: true/false, if link doesn't exist on database, we trigger scraping
	activeAddPeopleModalForProject: null, // can be null or id
	activeImportPercentage: 0, // upload percentage calculator
	bulkImportInfoOfCompany: null, // upload percentage calculator
	activeImportsForProject: [], // initial imports watcher
};

const linkedInBulkImportReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case IMPORT_LINKEDIN_PROFILE_IN_PROGRESS:
			return {
				...state,
				importLinkedInCandidateInProgress: action.payload,
			};
		case SET_IMPORT_LINKEDIN_PROFILE_INFO:
			return {
				...state,
				importLinkedInProfileInfo: action.payload,
			};

		case SEARCH_LINKEDIN_PROFILE_IN_PROGRESS_FOR_CURRENT_IMPORT:
			return {
				...state,
				searchCandidateForProject: action.payload,
			};

		case SET_BULK_IMPORT_ID_FOR_COMPANY:
			return {
				...state,
				bulkImportInfoOfCompany: action.payload,
			};

		case SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT:
			return {
				...state,
				candidateImportByLinkedInForProject: {
					...state.candidateImportByLinkedInForProject,
					[action.payload.id]: action.payload.data, // sets object for project by id
				},
			};

		case SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_COMPANY:
			return {
				...state,
				candidateImportByLinkedInForCompany: {
					...state.candidateImportByLinkedInForCompany,
					[action.payload.id]: action.payload.data, // sets object for company by id
				},
			};

		case ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT:
			return {
				...state,
				activeAddPeopleModalForProject: action.payload,
			};

		case ACTIVE_IMPORT_PERCENTAGE:
			return {
				...state,
				activeImportPercentage: action.payload,
			};

		case SET_CURRENT_IMPORT_FINISHED:
			return {
				...state,
				isCurrentImportFinished: action.payload,
			};

		case SET_SHOW_LAST_IMPORT_REPORT:
			return {
				...state,
				showLastBulkImportReport: action.payload,
			};

		case SET_SHOW_IMPORT_REPORT:
			return {
				...state,
				showBulkImportReport: action.payload,
			};

		case SET_SHOW_LAST_IMPORT_REPORT_FOR_COMPANY:
			return {
				...state,
				showLastBulkImportReportForCompany: action.payload,
			};

		case SET_BACKGROUND_BULK_IMPORTS_IN_PROGRESS_TO_WATCHER: {
			const {urls, activeProjectInState, response} = action.payload;
			const {id, target_list_id} = activeProjectInState;
			const {progress, linkedin_candidate_urls} = response.data;

			// Create a shallow copy of importLinkedInCandidatesInProgress
			const importLinkedInCandidatesInProgress = {
				...state.importLinkedInCandidatesInProgress,
			};

			linkedin_candidate_urls.forEach((url: {url: string; status: string}) => {
				const decodeAndGetLastSegment = (url: string) => {
					const segments = decodeURIComponent(url)
						.split("/")
						.filter((segment) => segment !== "");

					return segments.pop();
				};

				const lastSegmentFromAction = decodeAndGetLastSegment(url.url);

				for (const key in importLinkedInCandidatesInProgress) {
					if (
						Object.prototype.hasOwnProperty.call(
							importLinkedInCandidatesInProgress,
							key,
						)
					) {
						const candidate = importLinkedInCandidatesInProgress[key];
						if (Number(candidate.id) === id) {
							const lastSegmentFromState = decodeAndGetLastSegment(candidate.url);

							if (
								lastSegmentFromState === lastSegmentFromAction ||
								lastSegmentFromState + "/" === lastSegmentFromAction
							) {
								candidate.status = url.status;
								candidate.reason = "";
							}
						}
					}
				}
			});

			// Update state based on the payload
			return {
				...state,
				// Update search progress candidate by LinkedIn for the project
				searchCandidateForProject: {
					id: id,
					data: true,
					targetListId: target_list_id,
					project: activeProjectInState,
				},
				// Update active import percentage for the project
				activeImportPercentage: progress,
				candidateImportByLinkedInForProject: {
					...state.candidateImportByLinkedInForProject,
					[response.data.id]: {
						id: id,
						importInfo: response.data,
						status: "In Progress",
						projectInfo: activeProjectInState,
						targetListId: target_list_id,
						linkedInUrl: urls, // Adjusted to store the list of URLs
					},
				},
				// Directly update importLinkedInCandidatesInProgress
				importLinkedInCandidatesInProgress,
			};
		}

		case CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_IMPORT_BY_ID:
			const Data: {[key: number]: TSetImportLinkedinProfileInfoForCurrentImportPayload} = {
				...state.candidateImportByLinkedInForProject,
			};
			const DataProgressInfo: {
				[key: number]: TSetImportLinkedinProfileInfoForCurrentImportPayload;
			} = {...state.searchCandidateForProject};
			const ID = action.payload;

			const FilteredData = Object.values(Data).filter(
				(value) => Number(value.id) !== Number(ID),
			);
			const FilteredDataProgressInfo = Object.values(DataProgressInfo).filter(
				(value) => Number(value?.id) !== Number(ID),
			);

			return {
				...state,
				candidateImportByLinkedInForProject: {...FilteredData},
				searchCandidateForProject: {...FilteredDataProgressInfo},
			};

		case IMPORT_LINKEDIN_PROFILES_IN_PROGRESS:
			const updatedInProgress: {[key: string]: TProfilesImportInProgress} = {};

			action.payload.url.forEach((individualUrl: string, index: number) => {
				if (individualUrl.trim() !== "") {
					updatedInProgress[`${action.payload.id ?? 0}_${index + 1}`] = {
						id: action.payload.id ?? 0,
						url: individualUrl.trim(),
						status: action.payload.status,
						targetListId: action.payload.targetListId,
					};
				}
			});

			const previousInProgress = {...state.importLinkedInCandidatesInProgress};

			let hasMatchingId = false;
			for (const key in previousInProgress) {
				if (Number(previousInProgress[key]?.id) === action.payload.id) {
					hasMatchingId = true;
					break;
				}
			}

			if (hasMatchingId) {
				for (const key in previousInProgress) {
					if (
						Number(previousInProgress[key]?.id) === Number(action.payload.id) &&
						!updatedInProgress[key]
					) {
						delete previousInProgress[key];
					}
				}
			}

			return {
				...state,
				importLinkedInCandidatesInProgress: {
					...previousInProgress,
					...updatedInProgress,
				},
			};

		case DELETE_LINKEDIN_PROFILES_IN_PROGRESS:
			const importLinkedInCandidateInProgress = {
				...state.importLinkedInCandidatesInProgress,
			};

			for (const key in importLinkedInCandidateInProgress) {
				if (Object.prototype.hasOwnProperty.call(importLinkedInCandidateInProgress, key)) {
					if (
						Number(importLinkedInCandidateInProgress[key].id) ===
						action.payload.importID
					) {
						delete importLinkedInCandidateInProgress[key];
					}
				}
			}

			return {
				...state,
				importLinkedInCandidatesInProgress: importLinkedInCandidateInProgress,
			};

		case UPDATE_LINKEDIN_PROFILES_IN_PROGRESS:
			const updates = action.payload;

			const importLinkedInCandidatesInProgress = {
				...state.importLinkedInCandidatesInProgress,
			};

			const decodeAndGetLastSegment = (url: string) => {
				const segments = decodeURIComponent(url)
					.split("/")
					.filter((segment) => segment !== "");

				return segments.pop();
			};

			updates.forEach(
				({
					projectID,
					url,
					status,
					reason,
				}: {
					projectID: number;
					url: string;
					status: string;
					reason: string;
				}) => {
					const lastSegmentFromAction = decodeAndGetLastSegment(url);

					for (const key in importLinkedInCandidatesInProgress) {
						if (
							Object.prototype.hasOwnProperty.call(
								importLinkedInCandidatesInProgress,
								key,
							)
						) {
							const candidate = importLinkedInCandidatesInProgress[key];

							if (Number(candidate?.id) === projectID) {
								const lastSegmentFromState = decodeAndGetLastSegment(
									candidate?.url,
								);

								if (
									lastSegmentFromState === lastSegmentFromAction ||
									lastSegmentFromState + "/" === lastSegmentFromAction
								) {
									candidate.status = status;
									if (reason) {
										candidate.reason = reason;
									}
								}
							}
						}
					}
				},
			);

			return {
				...state,
				importLinkedInCandidatesInProgress,
			};

		default:
			return state;
	}
};

export default linkedInBulkImportReducer;
