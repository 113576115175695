import {TRootState} from "types";

export const isLastBulkImportReportDataLoadingSelector = (state: TRootState) =>
	state.backgroundTask.isLastBulkImportReportDataLoading;

export const lastCompletedCandidateImportByLinkedInForProjectSelector = (state: TRootState) =>
	state.backgroundTask.lastCompletedCandidateImportByLinkedInForProject;

export const activeLastCandidateAssessmentForProjectSelector = (state: TRootState) =>
	state.backgroundTask.activeCandidateAssessmentForProject;
