import type {FC} from "react";

import type {IconProps} from "./types";
import {InfoIcon} from "./index";
import SeniorityFilterHoverTooltip from "../../mapx-components/Accordion/Tooltips/SeniorityFilterHoverTooltip";
import React from "react";

const PreviewIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<div>
			<span data-tooltip-id={"seniority_filter_hover"}>
				<div style={{marginLeft: "5px"}} data-testid="info-icon">
					<InfoIcon />
				</div>
			</span>
			<SeniorityFilterHoverTooltip />
		</div>
		<mask
			x="0"
			y="0"
			width="24"
			height="24"
			id="mask0_422_9288"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_422_9288)">
			<path
				d="M12 16C13.25 16 14.3127 15.5627 15.188 14.688C16.0627 13.8127 16.5 12.75 16.5 11.5C16.5 10.25 16.0627 9.18733 15.188 8.312C14.3127 7.43733 13.25 7 12 7C10.75 7 9.68733 7.43733 8.812 8.312C7.93733 9.18733 7.5 10.25 7.5 11.5C7.5 12.75 7.93733 13.8127 8.812 14.688C9.68733 15.5627 10.75 16 12 16ZM12 14.2C11.25 14.2 10.6127 13.9373 10.088 13.412C9.56267 12.8873 9.3 12.25 9.3 11.5C9.3 10.75 9.56267 10.1123 10.088 9.587C10.6127 9.06233 11.25 8.8 12 8.8C12.75 8.8 13.3877 9.06233 13.913 9.587C14.4377 10.1123 14.7 10.75 14.7 11.5C14.7 12.25 14.4377 12.8873 13.913 13.412C13.3877 13.9373 12.75 14.2 12 14.2ZM12 19C9.56667 19 7.35 18.3207 5.35 16.962C3.35 15.604 1.9 13.7833 1 11.5C1.9 9.21667 3.35 7.39567 5.35 6.037C7.35 4.679 9.56667 4 12 4C14.4333 4 16.65 4.679 18.65 6.037C20.65 7.39567 22.1 9.21667 23 11.5C22.1 13.7833 20.65 15.604 18.65 16.962C16.65 18.3207 14.4333 19 12 19ZM12 17C13.8833 17 15.6127 16.504 17.188 15.512C18.7627 14.5207 19.9667 13.1833 20.8 11.5C19.9667 9.81667 18.7627 8.479 17.188 7.487C15.6127 6.49567 13.8833 6 12 6C10.1167 6 8.38733 6.49567 6.812 7.487C5.23733 8.479 4.03333 9.81667 3.2 11.5C4.03333 13.1833 5.23733 14.5207 6.812 15.512C8.38733 16.504 10.1167 17 12 17Z"
				fill="#404040"
			/>
		</g>
	</svg>
);

export default PreviewIcon;
