import {
	importLinkedInProfileForCompany,
	importLinkedInProfileToProject,
} from "store/mapx/linkedin-bulk-import/linkedInProfilesImportAsyncActions";
import {
	setBulkImportIdForCompany,
	setSearchProgressCandidateByLinkedInForCurrentImport,
} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";
import {TAppDispatch} from "types";
import {STCompany} from "api/companyApi/types";

export const searchCandidateByLinkedInUrlForProject =
	(
		linkedinUrls: string[],
		project: {
			id: number;
			target_list_id: number;
		},
	) =>
	async (dispatch: TAppDispatch) => {
		dispatch(
			setSearchProgressCandidateByLinkedInForCurrentImport({
				id: project?.id,
				data: true,
				targetListId: project?.target_list_id,
				project: project,
			}),
		);

		try {
			let response;

			const importResponse = await dispatch(
				importLinkedInProfileToProject(linkedinUrls, project?.id),
			);

			if (importResponse.status === 201) {
				const importInfo = importResponse.data;
				importInfo.iteration = 1;

				response = {candidate: null, message: "importing", importInfo};
			} else if (importResponse.status === 403) {
				response = {
					candidate: null,
					message: importResponse.data.message,
					importInfo: null,
				};
			}

			return response;
		} catch (error) {
			console.log(error);
		}

		return {candidate: null, message: "Error"};
	};

export const searchCandidateByLinkedInUrlForCompany =
	(linkedinUrls: string[], company: STCompany) => async (dispatch: TAppDispatch) => {
		dispatch(setBulkImportIdForCompany(company));
		dispatch(
			setSearchProgressCandidateByLinkedInForCurrentImport({
				id: 0,
				data: true,
				targetListId: null,
				project: null,
			}),
		);

		try {
			let response;

			const importResponse = await dispatch(importLinkedInProfileForCompany(linkedinUrls));

			if (importResponse.status === 201) {
				const importInfo = importResponse.data;
				importInfo.iteration = 1;

				response = {candidate: null, message: "importing", importInfo};
			} else if (importResponse.status === 403) {
				response = {
					candidate: null,
					message: importResponse.data.message,
					importInfo: null,
				};
			}

			return response;
		} catch (error) {
			console.log(error);
		}

		return {candidate: null, message: "Error"};
	};
