/** @format */

import {CancelToken} from "axios";

import HttpClient from "api/index";
import {STProjectsIdFrequentlyHiredFrom} from "api/projectApi/types";

class Index extends HttpClient {
	async getGenderDiversityReport(projectId: number) {
		return await this.doGet(`/mapx/gender-diversity-report?project_id=${projectId}`);
	}

	async getIndustriesReport(projectId: number, config = {}) {
		return await this.doGet(`/mapx/projects/${projectId}/industries-report`, config);
	}

	async getEthnicDiversityReport(projectId: number) {
		return await this.doGet(`/mapx/ethnic-diversity-report?project_id=${projectId}`);
	}

	async getSpecialismPositionsReport() {
		return await this.doGet("/mapx/specialism-positions-report");
	}

	async getJobFunctionDistribution(projectId: number) {
		return await this.doGet(`/mapx/specialism-positions-report?project_id=${projectId}`);
	}

	async getProjectStats(projectId: number, config = {}) {
		return this.doGet(`/mapx/projects/${projectId}/stats`, config);
	}

	async getCountryReport(projectId: number) {
		return await this.doGet(`/mapx/candidate-country-report?project_id=${projectId}`);
	}

	async getProjectFrequentlyHiredFrom(
		projectId: number,
		params: STProjectsIdFrequentlyHiredFrom,
		config: null | {cancelToken: CancelToken},
	) {
		return await this.doGet(`/mapx/projects/${projectId}/frequently-hired-from`, {
			...config,
			params,
		});
	}

	async getIndustriesBackgroundReport(projectId: number) {
		return this.doGet(`/industries-background-report?project_id=${projectId}`);
	}
}

const mapxProjectInsightsApi = new Index();

export default mapxProjectInsightsApi;
