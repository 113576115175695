import {userEmailSelector} from "store/mapx/user/userSelectors";
import {Mixpanel} from "helpers/mixpanel";
import axios, {CancelTokenSource} from "axios";
import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import {
	setActiveImportPercentage,
	setBulkImportCurrentImportFinished,
} from "./linkedInBulkImportActions";
import mapXBackgroundTaskAPI from "api/backgroundTaskApi";
import {TBackgroundTaskCreate} from "api/backgroundTaskApi/types";

const cancelTokens: {[key: string]: CancelTokenSource} = {};

export const importLinkedInProfileToProject =
	(linkedInLinks: string[], projectID?: number) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const userEmail = userEmailSelector(state);

		let response;

		try {
			const formBody: TBackgroundTaskCreate = {
				urls: linkedInLinks,
				project_id: projectID,
				type: "Candidates Bulk Import",
			};

			response = await mapXBackgroundTaskAPI.createBackgroundTaskRequest(formBody);

			if (response.status === 201) {
				try {
					response.data.linkedin_candidate_urls.map((url: string) => {
						Mixpanel.track(`LinkedIn Candidate Imported`, {
							name: "Imported from project",
							pageTitle: "Imported from project",
							url: url,
							distinct_id: userEmail,
						});
					});
				} catch (e) {
					console.log(e);
				}

				return response;
			}
		} catch (error) {
			console.log(error);

			return 403;
		}
	};

export const importLinkedInProfileForCompany =
	(linkedInLinks: string[]) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const userEmail = userEmailSelector(state);

		let response;

		try {
			const formBody: TBackgroundTaskCreate = {
				urls: linkedInLinks,
				type: "Candidates Bulk Import",
			};

			response = await mapXBackgroundTaskAPI.createBackgroundTaskRequest(formBody);

			if (response.status === 201) {
				try {
					response.data.linkedin_candidate_urls.map((url: string) => {
						Mixpanel.track(`LinkedIn Candidate Imported`, {
							name: "Imported from company",
							pageTitle: "Imported from company",
							url: url,
							distinct_id: userEmail,
						});
					});
				} catch (e) {
					console.log(e);
				}

				return response;
			}
		} catch (error) {
			console.log(error);

			return 403;
		}
	};

export const getIndividualBulkImportStatuses =
	(importId: number) => async (dispatch: Dispatch<TAppDispatch>) => {
		const requestKey = `GET_IMPORT_LINKEDIN_URL_STATUS_${importId}`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		let response: {
			data: {
				progress: number;
				status: string;
				project_id: number;
				linkedin_candidate_urls: {url: string; status: string}[];
				reason: string;
			};
			status: number;
		};

		try {
			response = await mapXBackgroundTaskAPI.getBackgroundTasksById(importId, config);

			if (response.data.status === "Completed" || response.data.status === "Error") {
				dispatch(setActiveImportPercentage(100));
			} else {
				dispatch(setActiveImportPercentage(response.data.progress));
			}

			if (response.data.progress === 100) {
				dispatch(
					setBulkImportCurrentImportFinished({
						importing: true,
						projectID: response.data.project_id,
					}),
				);
			}

			if (response.status === 200) {
				response.data.linkedin_candidate_urls.forEach((url) => {
					if (url.status === "Import Error" || url.status === "Scraping Error") {
						response.data.reason =
							"This profile has been sent for manual import. If it is a valid URL, it will be visible on MapX within 12 hours";
					} else if (url.status === "Not Found") {
						response.data.reason = "This profile was not found";
					}

					return {
						candidate: null,
						status: response.data.status,
						reason: response.data.reason,
						error: true,
					};
				});
			}

			return {
				candidate: null,
				reason: null,
				error: false,
				status: response.data.status,
				data: response.data,
			};
		} catch (error) {
			return {error};
		}
	};
