import {removeDuplicatesFromArray} from "helpers/filterHandlers";
import {STCompany} from "api/companyApi/types";
import {CompanyExtractedFilters} from "helpers/Filters/types";
import sizeOptions from "api/companyApi/companySizeBand.json";
import {TCompanyHeadcountRangeItem} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";

const handleSizeBand = (sizeValue: number) => {
	return sizeOptions.find(
		(size: TCompanyHeadcountRangeItem) =>
			sizeValue >= size.min_value && sizeValue <= size.max_value,
	);
};

const purifyOptions = (filterObject: CompanyExtractedFilters) => {
	if (filterObject.specialties_or.length > 0) {
		filterObject.specialties_or = removeDuplicatesFromArray(filterObject.specialties_or);
	}

	if (filterObject.industries.length > 0) {
		filterObject.industries = removeDuplicatesFromArray(filterObject.industries);
	} else {
		filterObject.industries = [];
	}

	if (filterObject.size?.length > 0) {
		filterObject.size = removeDuplicatesFromArray(filterObject.size);
	} else {
		filterObject.size = [];
	}

	return filterObject;
};

export const generateCompanyAttributeAsFilterSet = (
	companies: STCompany[],
	extractAttribute = true,
	extractCompanyId = false,
) => {
	const filterObject: CompanyExtractedFilters = {
		companies: [],
		industries: [],
		specialties_or: [],
		size: [],
	};

	if (companies !== undefined && Array.isArray(companies)) {
		companies.forEach((company) => {
			// for company search and extract filterOptionsApi, we don't need company id
			if (extractCompanyId && filterObject.companies) {
				if (company.id) {
					filterObject.companies = [...filterObject.companies, company];
				}
			}

			// for candidates list page, we only attach company ids and don't extract attributes
			// for company list page, we extract attribute
			if (extractAttribute) {
				if (company.specialties !== null) {
					filterObject.specialties_or = [
						...filterObject.specialties_or,
						...company.specialties.map((s) => s.id),
					];
				}

				if (company.industry && company.industry?.id) {
					filterObject.industries = [...filterObject.industries, company.industry.id];
				}

				if (company.size) {
					const selectedSizeOption = handleSizeBand(company.size);

					if (selectedSizeOption && selectedSizeOption.id) {
						filterObject.size = [...filterObject.size, selectedSizeOption.id];
					}
				}
			}
		});
	}

	return purifyOptions(filterObject);
};
