import React from "react";

const ArrowRight = (props) => (
	// <svg
	// 	width={20}
	// 	height={20}
	// 	fill="none"
	// 	xmlns="http://www.w3.org/2000/svg"
	// 	role="img"
	// 	{...props}
	// >
	// 	<mask
	// 		id="RightArrow_svg__a"

	// 		maskUnits="userSpaceOnUse"
	// 		x={0}
	// 		y={0}
	// 		width={20}
	// 		height={20}
	// 	>
	// 		<path fill="#D9D9D9" d="M0 0h20v20H0z" />
	// 	</mask>
	// 	<g mask="url(#RightArrow_svg__a)">
	// 		<path d="m7.83 15-1.166-1.167L10.497 10 6.664 6.167 7.831 5l5 5-5 5Z" fill="#888" />
	// 	</g>
	// </svg>

	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<mask
			id="mask0_90_10993"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_90_10993)">
			<path d="M8 15L6.9375 13.9375L10.875 10L6.9375 6.0625L8 5L13 10L8 15Z" fill="#CBC5C2" />
		</g>
	</svg>
);

export default ArrowRight;
