import {TAppDispatch, TRootState} from "types";
import {
	setCandidateIdsForAssessment,
	setCriteriaForProjectCandidateAssessment,
} from "store/mapx/project/projectActions";
import {additionalProfileFiltersSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {
	filteredSavedCandidatesSelector,
	targetListCandidatesSelector,
	targetListIDSelector,
} from "store/mapx/target-list/targetListSelectors";
import {SICandidate} from "api/candidateApi/types";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {STBackgroundTaskResponse, TBackgroundTaskCreate} from "api/backgroundTaskApi/types";
import mapXBackgroundTaskAPI from "api/backgroundTaskApi";
import {Mixpanel} from "helpers/mixpanel";
import {successResponse} from "helpers/map";
import {STProject} from "api/projectApi/types";
import {userEmailSelector} from "store/mapx/user/userSelectors";
import {setProjectCandidateAssessmentProcess} from "store/mapx/background-tasks/backgroundTaskActions";
import {
	briefCriteriaProjectAssessmentSelector,
	isCandidateSelectedForAssessmentSelector,
	selectedCandidateIdsForAssessmentSelector,
} from "store/mapx/project/candidateScoringSelectors";
import {Dispatch} from "react";
import axios, {CancelTokenSource} from "axios";
import {ICandidateByIdsApiRequestPayload} from "api/candidateApi/form";
import mapXCandidateApi from "api/candidateApi";
import {updateCandidateProfileAfterRefresh} from "store/mapx/refresh-candidate/refreshCandidateActions";
import {
	updateFilteredTargetListCandidatesWithSummary,
	updateTargetListCandidatesWithSummary,
} from "store/mapx/target-list/targetListActions";
import {getTargetListCandidates} from "store/mapx/target-list/targetListCandidatesAsyncActions";
import {getBackgroundTaskInfoById} from "store/mapx/background-tasks/backgroundTaskAsyncActions";
import {STFitToBriefCriteria} from "api/projectApi/candidateAssessment/types";
import candidateAssessment from "api/projectApi/candidateAssessment";

const cancelTokens: {[key: string]: CancelTokenSource} = {};

/**
 * Toggles the selection of a candidate for assessment in the current project.
 *
 * @param {number} candidateId The ID of the candidate to toggle selection for.
 * @returns A thunk that toggles the selection of the candidate.
 */

export const handleCandidateSelectionForAssessment =
	(candidateId: number) => (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const project: STProject = projectSelector(state);

			const isCandidateSelected = isCandidateSelectedForAssessmentSelector(
				state,
				project.id,
				candidateId,
			);

			const selectedCandidateIdsForAssessment: number[] =
				selectedCandidateIdsForAssessmentSelector(state);

			if (isCandidateSelected) {
				dispatch(
					setCandidateIdsForAssessment(
						selectedCandidateIdsForAssessment.filter((id) => id !== candidateId),
					),
				);
			} else {
				dispatch(
					setCandidateIdsForAssessment([
						...selectedCandidateIdsForAssessment,
						candidateId,
					]),
				);
			}
		} catch (e) {
			console.log(`Error from handleCandidateSelectionForAssessment: ${e}`);
		}
	};

/**
 * @function handleAllCandidateSelectionForAssessment
 * @description Handles the selection or deselection of all candidates in the target list.
 * @param {string} currentSelectedType A string indicating the type of selection to perform.
 *        The value can be one of the following:
 *        - "all": selects all candidates in the target list
 *        - "partial": selects only the candidates that match the current filter settings
 *        - "none": deselects all candidates in the target list
 * @returns A dispatch function that can be used to dispatch actions to the store
 */

export const handleAllCandidateSelectionForAssessment =
	(currentSelectedType: "all" | "partial" | "none") =>
	(dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const selectedFilters = additionalProfileFiltersSelector(state);

			const hasFilters = !!buildCandidateApiRequestPayload(selectedFilters, false);

			const targetListCandidates = targetListCandidatesSelector(state);

			const filteredCandidates = filteredSavedCandidatesSelector(state);

			const candidates = hasFilters ? filteredCandidates : targetListCandidates;

			let updatedSelectedIds = [];

			if (currentSelectedType === "none" || currentSelectedType === "partial") {
				updatedSelectedIds = candidates.map((candidate: SICandidate) => candidate.id);
			} else {
				updatedSelectedIds = [];
			}

			dispatch(setCandidateIdsForAssessment(updatedSelectedIds));
		} catch (e) {
			console.log(`Error from handleAllCandidateSelectionForAssessment: ${e}`);
		}
	};

/**
 * @function getProjectAllCriteriaForAssessment
 * @description Retrieves a list of project criteria that can be used for assessment.
 * @returns A promise that resolves to an array of project criteria. If the request fails,
 *          an empty array is returned.
 */

export const getProjectAllCriteriaForAssessment =
	() => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const project: STProject = projectSelector(state);

			const response = await candidateAssessment.getProjectBriefCriteria(project.id);

			if (successResponse(response, 200)) {
				dispatch(setCriteriaForProjectCandidateAssessment(response.data.results));

				return response.data;
			} else {
				return [];
			}
		} catch (e) {
			console.log(`Error from getAllProjectCriteriaForAssessment: ${e}`);
		}
	};

/**
 * @function handleCriteriaSaveForAssessment
 * @description Handles the saving of assessment criteria for a project. If the criteria
 *              already exists (has an ID), it updates the existing criteria. Otherwise,
 *              it creates a new criteria.
 * @param {STFitToBriefCriteria} criteria - The criteria object containing details such as
 *        criteria string, description, priority, and an optional ID. If ID is present,
 *        the criteria is updated; otherwise, a new criteria is created.
 * @returns A promise that resolves to "success" if the operation is
 *          successful, or "error" if it fails.
 * @throws Will log an error message if an exception occurs during the process.
 */

export const handleCriteriaSaveForAssessment =
	(criteria: STFitToBriefCriteria) => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const project: STProject = projectSelector(state);

			const briefCriteriaData: STFitToBriefCriteria[] =
				briefCriteriaProjectAssessmentSelector(state);

			let response;

			if (criteria?.id) {
				const formBody = {...criteria};
				delete formBody.id;

				response = await candidateAssessment.updateProjectBriefCriteria(
					project.id,
					criteria.id,
					formBody,
				);
			} else {
				response = await candidateAssessment.createProjectBriefCriteria(
					project.id,
					criteria,
				);
			}

			if (successResponse(response, 200) || successResponse(response, 201)) {
				if (criteria?.id) {
					const data = response.data;

					dispatch(
						setCriteriaForProjectCandidateAssessment([
							...briefCriteriaData.map((item: STFitToBriefCriteria) => {
								if (item.id === criteria.id) {
									return data;
								} else {
									return item;
								}
							}),
						]),
					);
				} else {
					dispatch(
						setCriteriaForProjectCandidateAssessment([
							...briefCriteriaData,
							response.data,
						]),
					);
				}

				return "success";
			} else {
				return response?.data?.message || "error";
			}
		} catch (e) {
			console.log(`Error from handleCriteriaSaveForAssessment: ${e}`);

			return "error";
		}
	};

/**
 * @function handleCriteriaDeleteForAssessment
 * @description Handles the deletion of a project's assessment criteria.
 * @param {number} criteriaId - The ID of the criteria to be deleted.
 * @returns A promise that resolves to "success" if the operation is
 *          successful, or "error" if it fails.
 * @throws Will log an error message if an exception occurs during the process.
 */

export const handleCriteriaDeleteForAssessment =
	(criteriaId: number) => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const project: STProject = projectSelector(state);

			const briefCriteriaData: STFitToBriefCriteria[] =
				briefCriteriaProjectAssessmentSelector(state);

			const response = await candidateAssessment.deleteProjectBriefCriteria(
				project.id,
				criteriaId,
			);

			if (successResponse(response, 204)) {
				dispatch(
					setCriteriaForProjectCandidateAssessment([
						...briefCriteriaData.filter(
							(item: STFitToBriefCriteria) => item.id !== criteriaId,
						),
					]),
				);

				return "success";
			} else {
				return "error";
			}
		} catch (e) {
			console.log(`Error from handleCriteriaDeleteForAssessment: ${e}`);
		}
	};

/**
 * This function creates a background task for candidate scoring for a project.
 *
 * It gets the selected candidate ids for assessment and creates a background task
 * with the project id and the selected candidate ids. It then dispatches an action
 * to set the project candidate assessment process with the background task.
 *
 * If the background task creation is successful, it also tracks an event with mixpanel.
 *
 * @returns A boolean indicating whether the background task creation was successful or not.
 */

export const handleCandidateScoringBackgroundProcess =
	() => async (dispatch: TAppDispatch, getState: TRootState) => {
		try {
			const state = getState();

			const userEmail = userEmailSelector(state);

			const project: STProject = projectSelector(state);

			const selectedCandidateIds = selectedCandidateIdsForAssessmentSelector(state);

			const formBody: TBackgroundTaskCreate = {
				type: "Candidate Assessment",
				project_id: project?.id,
				candidate_ids: selectedCandidateIds,
			};

			const response = await mapXBackgroundTaskAPI.createBackgroundTaskRequest(formBody);

			if (successResponse(response, 201)) {
				const backgroundTask: STBackgroundTaskResponse = response.data;

				dispatch(setProjectCandidateAssessmentProcess(backgroundTask));

				try {
					response.data.linkedin_candidate_urls.map((url: string) => {
						Mixpanel.track(`Candidate Scoring For Project`, {
							name: `${project.name}: Candidate Scoring`,
							pageTitle: `${project.name}`,
							url: url,
							distinct_id: userEmail,
						});
					});
				} catch (e) {
					console.log(e);
				}

				return true;
			}

			return false;
		} catch (e) {
			console.log(`Error from handleCandidateScoringBackgroundProcess: ${e}`);

			return false;
		}
	};

/**
 * Checks the status of a candidate assessment background task.
 *
 * @param {number} backgroundTaskId the id of the background task to check
 * @returns a promise that resolves to the background task info if the task is completed, or null if the task is still in progress
 */

export const checkCandidateAssessmentStatus =
	(backgroundTaskId: number) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		const requestKey = `GET_CANDIDATE_ASSESSMENT_STATUS`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		const state = getState();

		try {
			const data = (await dispatch(
				getBackgroundTaskInfoById(backgroundTaskId, config),
			)) as unknown as Nullable<STBackgroundTaskResponse>;

			if (data !== null) {
				const currentBackgroundTask: STBackgroundTaskResponse = data;

				dispatch(setProjectCandidateAssessmentProcess(currentBackgroundTask));

				const candidateIds = currentBackgroundTask.candidate_assessments
					.filter((a) => a.is_assessed)
					.map((a) => a.candidate_id);

				if (candidateIds.length > 0) {
					const targetListId = targetListIDSelector(state);

					const apiPayload: ICandidateByIdsApiRequestPayload = {
						filters: {
							candidates: candidateIds,
							target_list: targetListId,
						},
					};

					const response = await mapXCandidateApi.getCandidatesByIds(apiPayload, config);

					if (successResponse(response, 200)) {
						const results = response.data.results;

						const savedPeoplesInState = targetListCandidatesSelector(state);
						const filteredSavedPeoplesInState = filteredSavedCandidatesSelector(state);

						let updatedIds = [...candidateIds];

						const updatedPeopleForState: SICandidate[] = [];
						const updatedFilteredPeopleForState: SICandidate[] = [];

						savedPeoplesInState.forEach((candidate: SICandidate) => {
							const isCandidateInList = candidateIds.includes(candidate.id);
							const candidateFromResults = results.find(
								(c: SICandidate) => c.id === candidate.id,
							);
							const updatedCandidate = candidateFromResults ?? candidate;

							if (
								isCandidateInList &&
								updatedCandidate?.fit_to_brief &&
								updatedCandidate?.fit_to_brief?.assessments?.length > 0
							) {
								dispatch(updateCandidateProfileAfterRefresh(updatedCandidate));
								updatedIds = updatedIds.filter((id) => id !== candidate.id);
							}

							updatedPeopleForState.push(updatedCandidate);

							if (
								filteredSavedPeoplesInState.some(
									(c: SICandidate) => c.id === candidate.id,
								)
							) {
								updatedFilteredPeopleForState.push(updatedCandidate);
							}
						});

						dispatch(updateTargetListCandidatesWithSummary(updatedPeopleForState));

						dispatch(
							updateFilteredTargetListCandidatesWithSummary(
								updatedFilteredPeopleForState,
							),
						);
					}
				}

				if (currentBackgroundTask.status === "Completed") {
					dispatch(getTargetListCandidates());
				}

				return data;
			}
		} catch (e) {
			console.error(e);
		}
	};
