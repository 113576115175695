import React, {type FC, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {useHistory, useLocation} from "react-router-dom";

import withAuth from "withAuth";
import {Button, Search} from "components";
import {AddToProjectIcon, CloseIcon, PreviewIcon} from "assets/icons";
import {sendTrackedEventsData} from "store/mapx/events/eventTrackingAsyncActions";
import {ChangePasswordModal} from "mapx-components";
import useCompanyMappingProgressWatcher from "hooks/useMapThisFunctionProgressWatcher";
import {clearAPWorkflowState} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	createProjectModalSelector,
	projectSelector,
	projectsSelector,
} from "store/mapx/project-list/projectListSelectors";
import {createMarketMapModalSelector} from "store/mapx/market-map/marketMapSelectors";
import {getProjectList, setProjectAsync} from "store/mapx/project-list/projectListAsyncActions";
import AddFromMarketMapModal from "mapx-components/Modals/AddFromMarketMapModal";
import CreateMarketMapModal from "mapx-components/Modals/CreateMarketMapModal";
import PricingTableModal from "mapx-components/Modals/PricingTableModal";
import {
	useAppDispatch,
	useAppSelector,
	useImportLinkedInProfileProgressWatcher,
	useOutsideClick,
	useRefreshLinkedInProfileProgressWatcher,
	useSearchRequestProgressWatcher,
} from "hooks";
import {
	onboardingForStarterUserSelector,
	onboardingStepSelector,
} from "store/mapx/project/projectSelectors";
import {isSuperUserSelector, userOrganisationRoleSelector} from "store/mapx/user/userSelectors";
import {
	contactAdminModalSelector,
	pricingTableModalSelector,
} from "store/mapx/payment/paymentSelectors";
import {
	getOrganisationUsersForAdmin,
	getSeats,
} from "store/mapx/organisation/organisationAsyncFunctions";
import {getLicenseTypes, loadProfile} from "store/mapx/user/userAsyncAction";
import ContactAdminModal from "mapx-components/Modals/ContactAdminModal";

import styles from "./header.module.scss";
import MyProjects from "./MyProjects";
import SavedCompany from "./SavedCompany";
import SavedPeoples from "./SavedPeoples";
import {AiOutlineClose, AiOutlineSetting} from "react-icons/ai";
import useBulkImportLinkedInProfileProgressWatcher from "hooks/useBulkImportLinkedProfileProgressWatcher";
import ProjectCreateWithWorkflowsModal from "mapx-components/Modals/ProjectCreateWithWorkflowsModal";
import {
	getTargetListCandidatesDone,
	getTargetListCompaniesDone,
} from "store/mapx/target-list/targetListActions";
import {createProjectModal} from "store/mapx/project-list/projectListActions";
import {isSidebarExpandedSelector} from "store/mapx/layout/layoutSelectors";
import {Tooltip} from "react-tooltip";
import useCandidateContactDetailsInstantDisplay from "hooks/useCandidateContactDetailsInstantDisplay";
import useCandidateSummaryInstantDisplay from "hooks/useCandidateSummaryInstantDisplay";
import useProjectCandidateScoringProgressWatcher from "hooks/useProjectCandidateScoringProgressWatcher";

const NavbarV2: FC = () => {
	const ref = useRef<HTMLDivElement>(null);
	const settingsRef = useRef<HTMLDivElement>(null);

	const history = useHistory();

	const location = useLocation();

	const dispatch = useAppDispatch();

	const [pageScroll, setPageScroll] = useState<boolean>(false);

	const [[changePassModalDisplay], setChangePassModalDisplay] = useState<[boolean, null]>([
		false,
		null,
	]);

	const project = useAppSelector(projectSelector);
	const projects = useAppSelector(projectsSelector);

	const role = useAppSelector(userOrganisationRoleSelector);
	const isSuperUser = useAppSelector(isSuperUserSelector);

	const pricingTableModalOpen = useAppSelector(pricingTableModalSelector);
	const contactAdminModalOpen = useAppSelector(contactAdminModalSelector);
	const onboardingStep = useAppSelector(onboardingStepSelector);
	// const userAvailableCredit = useAppSelector(userAvailableCreditSelector);
	const createProjectModalOpen = useAppSelector(createProjectModalSelector);
	const createMarketMapModalOpen = useAppSelector(createMarketMapModalSelector);
	const onboardingForStarterUser = useAppSelector(onboardingForStarterUserSelector);

	const isSidebarExpanded = useAppSelector(isSidebarExpandedSelector);

	// RESPONSIVE HANDLE

	const [expanded, setExpanded] = useState(false);

	const headerClassName = classNames(styles.navbar, {
		[styles.scrolledNav]: pageScroll,
		[styles.shrinkNav]: isSidebarExpanded,
		[styles.navbar__is_show]: project?.name,
		[styles.navbar__is_z_index]: onboardingForStarterUser,
		[styles.navbar__is_z_index_max]: onboardingStep === 6,
		[styles.expanded]: expanded,
	});

	useOutsideClick(ref, () => setChangePassModalDisplay([false, null]));

	useOutsideClick(settingsRef, () => setExpanded(false));

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const handleScroll = () => {
		if (window.scrollY > 0) {
			setPageScroll(true);
		} else {
			setPageScroll(false);
		}
	};

	// SYNC BEHAVIOR TRACKING DATA TO BE
	const tracked_events = useAppSelector((state) => state.events.tracked_events);
	const currentRef = useRef(true);

	/**
	 *** UPDATE PLAN MODAL TRIGGERING LOGIC
	 **/

	// @TODO:  It is incomplete because no needed for now. Remove if not needed.

	// const seatsDistribution = useSelector(orgPlanDistributionSelector);
	// const loadingUsers = useSelector(orgUsersListProgressSelector);
	// const seatsInProgress = useSelector(seatsInProgressSelector);

	// // Logic to show "Plan Update" modal.
	// useEffect(() => {
	// 	if (role === "Admin" && !loadingUsers && !seatsInProgress) {
	// 		// @TODO: Show "Update Plan" modal here
	// 	}
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [role, seatsDistribution]);

	useEffect(() => {
		const interval = setInterval(async () => {
			if (tracked_events?.length) {
				await dispatch(sendTrackedEventsData(tracked_events));
			}
		}, 30000);

		return () => {
			clearInterval(interval);
			currentRef.current = false;
		};
	}, [dispatch, tracked_events]);

	useEffect(() => {
		if (projects?.length === 0) {
			dispatch(getProjectList());
		}
		if (role && !isSuperUser) {
			dispatch(getOrganisationUsersForAdmin());
			if (role === "Admin") {
				dispatch(getLicenseTypes());
				dispatch(getSeats());
			}
		}

		dispatch(loadProfile());
	}, [dispatch, role]); // eslint-disable-line react-hooks/exhaustive-deps

	/**
	 *** IMPORT LINKEDIN CANDIDATE WATCHING GLOBALLY
	 **/

	useImportLinkedInProfileProgressWatcher();
	useRefreshLinkedInProfileProgressWatcher();

	/**
	 *** BULK IMPORT LINKEDIN CANDIDATE WATCHING GLOBALLY
	 **/

	useBulkImportLinkedInProfileProgressWatcher();

	/**
	 *** COMPANY MAPPING WATCHING GLOBALLY
	 **/

	useCompanyMappingProgressWatcher();

	/**
	 *** SEARCH REQUESTS PROGRESS WATCHING GLOBALLY
	 **/

	useSearchRequestProgressWatcher();

	/**
	 *** GENERATE CONTACT DETAILS PROGRESS WATCHING GLOBALLY
	 **/

	useCandidateContactDetailsInstantDisplay();

	/**
	 *** GENERATE SUMMARY PROGRESS WATCHING GLOBALLY
	 **/

	useCandidateSummaryInstantDisplay();

	/**
	 *** CANDIDATE SCORING PROGRESS WATCHING GLOBALLY
	 **/

	useProjectCandidateScoringProgressWatcher();

	const paramsProjectId = Number(location.pathname.replace("/project/", ""));

	const handleViewSelectedProject = () => {
		if (paramsProjectId !== project?.id) {
			dispatch(clearAPWorkflowState());
			dispatch(setProjectAsync(project));
			history.push(`/project/${project?.id}`);
		}
	};

	// const shouldDisplayAvailableCreditInfo = useMemo(() => {
	// 	return history?.location?.pathname !== "/home" && userAvailableCredit <= 500;
	// }, [history?.location?.pathname, userAvailableCredit]);

	// const shouldDisplayStartSearch = useMemo(
	// 	() => history?.location?.pathname !== "/" && history?.location?.pathname !== "/home",
	// 	[history?.location?.pathname],
	// );

	const handleExitProjectClick = () => {
		if (project) {
			dispatch(setProjectAsync(null));
			dispatch(getTargetListCandidatesDone([]));
			dispatch(getTargetListCompaniesDone([]));
		}

		if (paramsProjectId) {
			history.push("/projects");
		}
	};

	const iconWithToolTip = () => (
		<div>
			<span data-tooltip-id={"view_project_hover"}>
				<div data-testid="info-icon">
					<PreviewIcon />
				</div>
			</span>
			<Tooltip
				float={true}
				id={"view_project_hover"}
				clickable={false}
				place="left"
				delayShow={0}
				className={styles.previewIconToolTip}
			>
				<small style={{padding: "0"}}>View Project</small>
			</Tooltip>
		</div>
	);

	return (
		<div ref={settingsRef}>
			<div className={styles.settingsGear} onClick={() => setExpanded((prev) => !prev)}>
				{!expanded && <AiOutlineSetting size={25} color={"#808080"} />}

				{expanded && <AiOutlineClose size={23} color={"#808080"} />}
			</div>

			<div className={headerClassName}>
				{/*{(userLicenseTypeName === "Pro" || isSuperUser) && <Search />}*/}

				<Search />

				<div
					className={classNames(styles.right_block, {
						[styles.right_block__is_view]: project?.name,
					})}
				>
					<div
						className={classNames(styles.right_block__left_part, {
							[styles.right_block__left_part__is_show]: project?.name,
						})}
					>
						{project?.name ? (
							<a>
								<Button
									defaultBtnStyle
									LeftIcon={iconWithToolTip}
									onClick={handleViewSelectedProject}
									className={styles.right_block__left_part__view}
									leftIconClasses={styles.right_block__left_part__view__icon}
								></Button>
							</a>
						) : (
							<Button
								defaultBtnStyle
								onClick={() => dispatch(createProjectModal(true))}
								className={styles.right_block__left_part__view}
								leftIconClasses={styles.right_block__left_part__view__icon}
							>
								<AddToProjectIcon />
							</Button>
						)}
						<MyProjects />

						{/*{shouldDisplayAvailableCreditInfo && (*/}
						{/*	<Button*/}
						{/*		defaultBtnStyle*/}
						{/*		LeftIcon={CreditCardIcon}*/}
						{/*		className={styles.right_block__left_part__view}*/}
						{/*		leftIconClasses={styles.right_block__left_part__view__icon}*/}
						{/*	>*/}
						{/*		<span className={styles.creditInfo}>{userAvailableCredit}</span>*/}
						{/*	</Button>*/}
						{/*)}*/}

						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyItems: "flex-end",
							}}
						>
							<SavedPeoples />

							<SavedCompany className={styles.right_block__left_part__company} />

							<div
								className={classNames(styles.right_block__left_part__exit, {
									[styles.right_block__left_part__noProject]:
										!project?.id || !paramsProjectId,
									[styles.right_block__left_part__hasProject]:
										project?.id || paramsProjectId,
								})}
							>
								<span
									onClick={(e) => {
										e.stopPropagation();
										handleExitProjectClick();
									}}
									className={styles.list__scroll__item_right_close}
								>
									<span style={{opacity: project?.id ? "1" : ".3"}}>
										<CloseIcon title={"Exit Project"} />
									</span>
								</span>
							</div>
						</div>
					</div>
					{/*{shouldDisplayStartSearch && location?.pathname !== "/onboarding/home" && (*/}
					{/*	<div className={styles.startNewSearch__button}>*/}
					{/*		*/}

					{/*		<OnboardingTooltip*/}
					{/*			className={styles.startNewSearch__button__boarding}*/}
					{/*			step={*/}
					{/*				onboardingStep === EOnboardingTooltipSteps.Sixth*/}
					{/*					? onboardingStep*/}
					{/*					: null*/}
					{/*			}*/}
					{/*		/>*/}
					{/*	</div>*/}
					{/*)}*/}

					{/* @TODO: For further use */}
					{/* <Button className={styles.right_block__notification} defaultBtnStyle>
						<NotificationsIcon /> <NotificationsEmptyIcon />
					</Button> */}

					<ChangePasswordModal
						ref={ref}
						changePassModalDisplay={changePassModalDisplay}
						setChangePassModalDisplay={setChangePassModalDisplay}
					/>
				</div>
			</div>

			{/* We should not make this modal unmounted when it is closed because "search" state in it should resend the request when modal is closed to not break other components that use modal_results of market-map reducer */}
			<AddFromMarketMapModal />

			{pricingTableModalOpen && <PricingTableModal />}

			{contactAdminModalOpen && <ContactAdminModal />}

			{createProjectModalOpen && <ProjectCreateWithWorkflowsModal />}

			{createMarketMapModalOpen && <CreateMarketMapModal />}
		</div>
	);
};

export default withAuth(NavbarV2);
