import React, {useMemo, useRef} from "react";
import styles from "mapx-components/Modals/FooterButtons/styles.module.scss";
import {GeneralButton} from "mapx-components/index";
import ContinueButtonIcon from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/Icons/ContinueButtonIcon";
import {RoundedPlus} from "assets/icons";
import {TFooterButtonsProps} from "mapx-components/Modals/FooterButtons/types";

const FooterButtons = ({
	handleActionButtonClick,
	handleGoButtonClick,
	actionButtonLabel,
	isActionButtonDisabled,
	isLoading = false,
}: TFooterButtonsProps) => {
	const footerRef = useRef<Nullable<HTMLDivElement>>(null);

	const ACTION_BUTTON_ICON = useMemo(() => {
		if (actionButtonLabel === "Continue") {
			return <ContinueButtonIcon />;
		} else {
			return <RoundedPlus color={"#fff"} />;
		}
	}, [actionButtonLabel]);

	return (
		<div ref={footerRef} className={styles.footer}>
			{handleGoButtonClick ? (
				<div className={styles.goBack} onClick={handleGoButtonClick}>
					Go Back
				</div>
			) : (
				<div />
			)}

			<div id={"footer-button"}>
				<GeneralButton
					customClass={styles.continueButton}
					title={actionButtonLabel}
					icon={ACTION_BUTTON_ICON}
					handleClick={handleActionButtonClick}
					disabled={isActionButtonDisabled}
					loadingSave={isLoading}
					onKeyDown={(event) => {
						console.log(event.code);
					}}
					tabIndex={0}
				/>
			</div>
		</div>
	);
};

export default FooterButtons;
