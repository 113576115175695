// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO: Add state type

import {createSelector} from "reselect";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

export const targetListCompanyInProgressSelector = (state) =>
	state.targetList.targetListCompanyInProgress;

export const targetListCandidateInProgressSelector = (state) =>
	state.targetList.targetListCandidateInProgress;

export const targetListCompaniesSelector = (state) => state.targetList.targetListCompanies;
export const filteredSavedPeopleFetchIsInProgressSelector = (state) =>
	state.targetList.filteredSavedPeopleFetchIsInProgress;
export const filteredSavedCandidatesSelector = (state) => state.targetList.filteredSavedCandidates;
export const filteredSavedCandidatesPaginationSelector = (state) =>
	state.targetList.filteredSavedCandidatesPagination;

export const targetCompaniesPaginationSelector = (state) =>
	state.targetList.targetCompaniesPagination;

export const targetListCompanyIdsSelector = createSelector(
	[targetListCompaniesSelector],
	(targetListCompanies) => new Set(targetListCompanies.map((company) => company.id)),
);

export const targetListCandidatesSelector = (state) => state.targetList.targetListCandidates;
// export const moreSavedPeopleInProgress = (state) => state.targetList.moreSavedPeopleFetchInProgress;
export const targetCandidatesPaginationSelector = (state) =>
	state.targetList.targetCandidatesPagination;

export const targetListsInProgressSelector = (state) => state.targetList.targetListsInProgress;

export const targetListsSelector = (state) => state.targetList.targetLists;

export const savingCompaniesByFilterInProgressSelector = (state) =>
	state.targetList.savingCompaniesByFilterInProgress;

export const savingCandidatesByFilterInProgressSelector = (state) =>
	state.targetList.savingCandidatesByFilterInProgress;

export const addingFromMarketMapInProgressSelector = (state) =>
	state.targetList.adding_from_market_map_in_progress;

//
// TODO: Remove all usages of the code below (not the selector) after the BE implementaiton.
//
//	Code below has to be added everytime targetListIDSelector is used in order
//  to prevent bugs when there is no detached targetListApi list on DB:
//
// 		if (!targetListID) {
// 			const {targetList} = await dispatch(createDetachedTargetList());
// 			targetListID = targetList.id;
// 		}
//
export const targetListIDSelector = createSelector(
	[projectSelector, targetListsSelector],
	(project, targetLists) => {
		if (project && project.id) {
			return project.target_list_id;
		}

		const targetList = targetLists.find((tl) => tl.project_id === null);

		// TODO: Should not be returning null, remove this
		// 		 condition after the BE implementation.
		return targetList ? targetList.id : null;
	},
);

export const detachedTargetListSelector = createSelector([targetListsSelector], (targetLists) =>
	targetLists.find((tl) => tl.project_id === null),
);

export const candidatesTargetListSelector = (state) => state.targetList.targetListCandidates;

export const addingCompanyToTargetListInProgressSelector = (state) =>
	state.targetList.addingCompanyToTargetListInProgress;

export const addingCandidateToTargetListInProgressSelector = (state) =>
	state.targetList.addingCandidateToTargetListInProgress;

export const removingCandidateFromTargetListInProgressSelector = (state) =>
	state.targetList.removingCandidateFromTargetListInProgress;

export const removingCompanyFromTargetListInProgressSelector = (state) =>
	state.targetList.removingCompanyFromTargetListInProgress;

export const removingCandidatesFromTargetListInProgressSelector = (state) =>
	state.targetList.removingCandidatesFromTargetListInProgress;

export const removingCompaniesFromTargetListInProgressSelector = (state) =>
	state.targetList.removingCompaniesFromTargetListInProgress;

export const isCandidateBeingAddedToTargetListSelector = createSelector(
	[addingCandidateToTargetListInProgressSelector, (_, id) => id],
	(candidatesThatAreBeingAddedToTargetList, candidateID) => {
		return candidatesThatAreBeingAddedToTargetList.includes(candidateID);
	},
);

export const isCompanyBeingAddedToTargetListSelector = createSelector(
	[
		addingCompanyToTargetListInProgressSelector,
		savingCompaniesByFilterInProgressSelector,
		(_, id) => id,
	],
	(companiesThatAreBeingAddedToTargetList, savingCompaniesByFilter, companyID) => {
		return (
			savingCompaniesByFilter || companiesThatAreBeingAddedToTargetList.includes(companyID)
		);
	},
);

export const isCandidateBeingRemovedFromTargetListSelector = createSelector(
	[removingCandidateFromTargetListInProgressSelector, (_, id) => id],
	(candidatesThatAreBeingRemovedFromTargetList, candidateID) => {
		return candidatesThatAreBeingRemovedFromTargetList.includes(candidateID);
	},
);

export const isCompanyBeingRemovedFromTargetListSelector = createSelector(
	[removingCompanyFromTargetListInProgressSelector, (_, id) => id],
	(companiesThatAreBeingRemovedFromTargetList, companyID) => {
		return companiesThatAreBeingRemovedFromTargetList.includes(companyID);
	},
);

export const isCandidateSavedSelector = createSelector(
	[candidatesTargetListSelector, (_, id) => id],
	(candidatesTargetList, candidateID) => {
		return candidatesTargetList.some((candidate) => candidate.id === candidateID);
	},
);

export const isCompanySavedSelector = createSelector(
	[targetListCompaniesSelector, (_, id) => id],
	(targetListCompanies, companyID) => {
		return targetListCompanies.some((company) => company.id === companyID);
	},
);
