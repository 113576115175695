import {refreshCandidateReducerTypes} from "store/mapx/refresh-candidate/types";
import {
	CLEAR_REFRESH_LINKEDIN_PROFILE_SCRAPE_INFO,
	CURRENT_REFRESH_LINKEDIN_PROFILE_SCRAPE_INFO,
	REFRESH_LINKEDIN_PROFILE_IN_PROGRESS,
} from "store/mapx/mapXActionTypes";
import {AnyAction} from "redux";

const initialState: refreshCandidateReducerTypes = {
	refreshLinkedInCandidatesInProgress: {},
	currentRefreshLinkedInProfilesScrapeInfo: {},

	// candidatesRefreshByLinkedInForProject: null, // used for project // Structure projectId: [1, 2, 3] candidate ids
	// activeAddPeopleModalForProject: null, // can be null or id
};

const refreshCandidateReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case REFRESH_LINKEDIN_PROFILE_IN_PROGRESS:
			return {
				...state,
				refreshLinkedInCandidatesInProgress: {
					...state.refreshLinkedInCandidatesInProgress,
					...action.payload,
				},
			};
		case CURRENT_REFRESH_LINKEDIN_PROFILE_SCRAPE_INFO:
			return {
				...state,
				currentRefreshLinkedInProfilesScrapeInfo: {
					...state.currentRefreshLinkedInProfilesScrapeInfo,
					...action.payload,
				},
			};

		case CLEAR_REFRESH_LINKEDIN_PROFILE_SCRAPE_INFO:
			const linkedInUrl = action.payload;
			const updatedRefreshInProgress = {...state.refreshLinkedInCandidatesInProgress};
			const updatedRefreshScrapeInfo = {...state.currentRefreshLinkedInProfilesScrapeInfo};

			if (updatedRefreshInProgress[linkedInUrl] !== undefined) {
				delete updatedRefreshInProgress[linkedInUrl];
			}

			if (updatedRefreshScrapeInfo[linkedInUrl] !== undefined) {
				delete updatedRefreshScrapeInfo[linkedInUrl];
			}

			return {
				...state,
				refreshLinkedInCandidatesInProgress: {...updatedRefreshInProgress},
				currentRefreshLinkedInProfilesScrapeInfo: {...updatedRefreshScrapeInfo},
			};

		// case SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_CURRENT_IMPORT:
		//     return {
		//         ...state,
		//         candidateImportByLinkedInForProject: {
		//             ...state.candidateImportByLinkedInForProject,
		//             [action.payload.id]: action.payload.data, // sets object for project by id
		//         },
		//     };
		//
		// case CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT:
		//     const data = {...state.candidateImportByLinkedInForProject};
		//     const dataProgressInfo = {...state.searchCandidateForProject};
		//     const id = action.payload;
		//
		//     if (id in data) {
		//         delete data[id];
		//     }
		//
		//     if (id in dataProgressInfo) {
		//         delete dataProgressInfo[id];
		//     }
		//
		//     return {
		//         ...state,
		//         candidateImportByLinkedInForProject: {...data},
		//         searchCandidateForProject: {...dataProgressInfo},
		//     };
		//
		// case ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT:
		//     return {
		//         ...state,
		//         activeAddPeopleModalForProject: action.payload,
		//     };

		default:
			return state;
	}
};

export default refreshCandidateReducer;
