import React, {useEffect, useMemo, useState} from "react";
import styles from "./styles.module.scss";
import ProfileRequestConfigurator from "./ProfilesRequestConfigurator";
import SearchRequestResults from "mapx-components/SearchRequestResults";
import {TProjectsResultsTabContentTypes} from "mapx-components/SearchRequestResults/FilterBlock/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {activeProjectResultsTabContentSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import Instruction from "mapx-pages/CandidateSearch/AIWebSearch/Instruction";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {
	clearActiveSearchRequest,
	clearAPActiveResult,
	clearAPFilters,
	setAPCandidateTargetListToggle,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {getAllSearchRequestsByType} from "store/mapx/additional-profiles/searchRequestAsyncActions";
import {STProject} from "api/projectApi/types";
import ResultsBlockLoader from "mapx-components/SearchRequestResults/ContentBlock/ResultsBlockLoader";

const CandidateAIWebSearch = () => {
	const dispatch = useAppDispatch();

	const [loadingSearchRequests, setLoadingSearchRequests] = useState(false);

	const activeContent: TProjectsResultsTabContentTypes = useAppSelector(
		activeProjectResultsTabContentSelector,
	);

	const project: STProject = useAppSelector(projectSelector);

	const shouldDisplayConfigWithInstruction = useMemo(() => {
		if (!project) return true;

		return !loadingSearchRequests && activeContent === "profile_request";
	}, [activeContent, loadingSearchRequests, project]);

	useEffect(() => {
		async function get() {
			setLoadingSearchRequests(true);

			await dispatch(getAllSearchRequestsByType(project.id, "Additional Profiles"));

			setLoadingSearchRequests(false);
		}

		if (project) {
			get();
		}

		return () => {
			dispatch(clearActiveSearchRequest());
			dispatch(clearAPActiveResult());
			dispatch(clearAPFilters());
			dispatch(setAPCandidateTargetListToggle(false));
		};
	}, [dispatch, project]);

	return (
		<div className={styles.container}>
			{loadingSearchRequests && <ResultsBlockLoader />}

			{shouldDisplayConfigWithInstruction && <ProfileRequestConfigurator />}

			{shouldDisplayConfigWithInstruction && <Instruction />}

			{project && !loadingSearchRequests && activeContent === "search_results" && (
				<SearchRequestResults
					searchType={"Additional Profiles"}
					setLoadingSearchRequests={setLoadingSearchRequests}
				/>
			)}
		</div>
	);
};

export default CandidateAIWebSearch;
